/**
 * str: string, // The string that needs to be cut out
 * maxCharacters: int // Total allowed characters
 */
export const renderCappedString = (str, maxCharacters) => {
    if (str) {
        if (str.length > maxCharacters) {
            return str.substring(0, maxCharacters) + "..."
        } else {
            return str
        }
    }
    return str
}

export function updateObjectByKey (key, value, obj) {
    if (!obj) throw new Error('Please pass the object where the key needs to be changed as third argument');
    var newObj = { ...obj }
    const keyChain = key.split(".")
    const currentKey = keyChain[0]
    const lastKey = keyChain.length === 1
    var arrIndex = -1;
    var arrKey = null;
    if (currentKey.includes("[") && currentKey.includes("]")) {
        arrKey = currentKey.split("[")[0]
        arrIndex = parseInt(currentKey.split("[")[1].slice(0, -1));
    }
    if (keyChain.length === 0) return newObj
    if (newObj instanceof Object) {
        if (currentKey in newObj || arrKey in newObj) {
            if (arrIndex !== -1 && arrKey !== null) {
                if (lastKey) {
                    newObj[arrKey] = newObj[arrKey].map((o, i) => i === arrIndex ? value : o)
                    return newObj
                } 
                newObj = { 
                            ...newObj, 
                            [arrKey]: updateObjectByKey(keyChain.slice(1).join("."), value, newObj[arrKey][arrIndex]
                            )
                        
                }
            } else {
                if (lastKey) { 
                    newObj[currentKey] = value
                    return newObj
                }

                newObj = { ... newObj, [currentKey]: updateObjectByKey(keyChain.slice(1).join("."), value, newObj[currentKey]) }
                
            }
        }
        return newObj
    } else {
        return newObj
    }
}
export function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
    let result = '';
    const charactersLength = characters.length;
    
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    
    return result;
  }

export function calculateDistanceBetweenLocations(loc1, loc2, isKm = false) {
    const R = isKm ? 6371 : 3958.8; // Radius of the Earth in kilometers
    const dLat = deg2rad(loc2.lat - loc1.lat); // Convert degrees to radians
    const dLon = deg2rad(loc2.lng - loc1.lng); // Convert degrees to radians
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(loc1.lat)) * Math.cos(deg2rad(loc2.lat)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
}

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}
export function timeDifferenceString(date) {
    const now = new Date();
    const inputDate = new Date(date);
    const diffInSeconds = Math.floor((inputDate - now) / 1000);
    const isPast = diffInSeconds < 0;
    const absDiffInSeconds = Math.abs(diffInSeconds);

    const seconds = absDiffInSeconds % 60;
    const minutes = Math.floor(absDiffInSeconds / 60) % 60;
    const hours = Math.floor(absDiffInSeconds / (60 * 60)) % 24;
    const days = Math.floor(absDiffInSeconds / (60 * 60 * 24)) % 7;
    const weeks = Math.floor(absDiffInSeconds / (60 * 60 * 24 * 7)) % 4;
    const months = Math.floor(absDiffInSeconds / (60 * 60 * 24 * 30)) % 12;
    const years = Math.floor(absDiffInSeconds / (60 * 60 * 24 * 365));

    if (isPast) {
        if (years > 0) return `${years} year${years > 1 ? 's' : ''} passed`;
        if (months > 0) return `${months} month${months > 1 ? 's' : ''} passed`;
        if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} passed`;
        if (days > 0) return `${days} day${days > 1 ? 's' : ''} passed`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} passed`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} passed`;
        return `${seconds} second${seconds > 1 ? 's' : ''} passed`;
    } else {
        if (years > 0) return `${years} year${years > 1 ? 's' : ''} left`;
        if (months > 0) return `${months} month${months > 1 ? 's' : ''} left`;
        if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} left`;
        if (days > 0) return `${days} day${days > 1 ? 's' : ''} left`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} left`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} left`;
        return `${seconds} second${seconds > 1 ? 's' : ''} left`;
    }
}
