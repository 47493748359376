import React, { useEffect, useRef, useState } from "react";
import ContentContainer from "../../atoms/ContentContainer";
import {
  Divider,
  Grid,
  Switch,
  Chip,
  Button,
  Alert,
  IconButton,
} from "@mui/material";
import { generateRandomString } from "../../helpers/utils";
import axios from "../../config/axios";
import CloseIcon from "@mui/icons-material/Close";
import { getAdminInfo } from "../../helpers/auth";
import DeliverySettingsInfo from "./DeliverySettingsInfo";
import Tabview from "../../atoms/Tabview/Tabview";
import DeliverySettingsTime from "./DeliverySettingsTime";
import FormSkeletalLoader from "../../atoms/Loader/FormSkeletalLoader";

function DeliverySettings() {
  const [input, setInput] = useState({
    deliveryEnabled: false,
    radius: 1,
    deliveryCharge: 0,
    taxProfile: null,
    exceptionZipcodes: "",
    chargeIncreamentByDistanceEnabled: false,
    maxDistanceForBaseDeliveryCharge: 0,
    increamentPerMiles: 0,
    minimumOrderAmount: 0,
    /*
    {
        id: string,
        city: google map place_id,
        deliveryCharge: number,
        taxProfile: taxProfileId, default: "parent"
    }
    */
    customDeliveryCharges: [],
  });
  const [deliveryTimeStructure, setDeliveryTimeStructure] = useState(null);
  const citySearchRefsRef = useRef({});
  const [citySearchRefs, _setCitySearchRefs] = useState({});
  const setCitySearchRefs = (value) => {
    citySearchRefsRef.current = value;
    _setCitySearchRefs(value);
  };

  const [eligibleCities, setElibileCities] = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [taxProfiles, setTaxProfiles] = useState([]);
  // info | timing
  const [currentTab, setCurrentTab] = useState("info");
  const [feedback, setFeedback] = useState({
    isSuccess: false,
    isOpen: false,
    message: "",
  });

  useEffect(() => {
    fetchDeliverySettingsData();
  }, []);

  useEffect(() => {
    Object.keys(citySearchRefs).forEach((key) => {
      if (citySearchRefs[key]) {
        const adminInfo = getAdminInfo();
        const bounds = {
          north:
            adminInfo.location.coordinates[0] + (input.radius * 1.60934) / 100,
          south:
            adminInfo.location.coordinates[0] - (input.radius * 1.60934) / 100,
          east:
            adminInfo.location.coordinates[1] + (input.radius * 1.60934) / 100,
          west:
            adminInfo.location.coordinates[1] - (input.radius * 1.60934) / 100,
        };
        console.log("Bounds", adminInfo.location.coordinates, bounds);
        const options = {
          bounds,
          componentRestrictions: { country: ["us", "ca"] },
          fields: [
            "address_components",
            "formatted_address",
            "geometry",
            "icon",
            "name",
          ],
          types: ["(cities)"],
          strictBounds: false,
        };
        const autocomplete = new window.google.maps.places.Autocomplete(
          citySearchRefs[key],
          options
        );
        // setAutoCompleteInstances([...autoCompleteInstances, autocomplete]);
        window.google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            const place = autocomplete.getPlace();
            console.log("Place", place.address_components);
            console.log("Place", place.formatted_address);
            console.log("Place", place.geometry);
            console.log("Place", place.name);

            let includeEverything = false;
            const locality = place.address_components.find((address) =>
              address.types.includes("locality")
            ).short_name;
            const country = place.address_components.find((address) =>
              address.types.includes("country")
            ).short_name;
            let addressString = place.address_components.reduce((acc, curr) => {
              if (includeEverything) {
                acc += `${curr.short_name},`;
                return acc;
              } else {
                if (curr.types.includes("locality")) {
                  includeEverything = true;
                  acc += `${curr.short_name},`;
                  return acc;
                }
                return acc;
              }
            }, "");
            addressString = addressString.replace(/,\s*$/, "");

            setInput({
              ...input,
              customDeliveryCharges: input.customDeliveryCharges.map(
                (charge) => {
                  if (charge.id === key) {
                    return {
                      ...charge,
                      city: {
                        formatted_address: place.formatted_address,
                        unique_address_string: addressString,
                      },
                    };
                  }
                  return charge;
                }
              ),
            });
          }
        );
      }
    });
  }, [citySearchRefs, input.radius]);

  const handleInputChange = (key, value) => {
    setInput({
      ...input,
      [key]: value,
    });
  };
  const handleRadiusInputChange = (value) => {
    setInput({
      ...input,
      radius: value,
    });
  };
  const handleAddNewCustomDeliveryCharge = () => {
    const newId = generateRandomString(6);
    setInput({
      ...input,
      customDeliveryCharges: [
        ...input.customDeliveryCharges,
        {
          id: newId,
          // isNew: true,
          city: {
            formatted_address: "",
            unique_address_string: "",
          },
          deliveryCharge: input.deliveryCharge ?? 0,
          taxProfile: "parent",
        },
      ],
    });
    setCitySearchRefs({
      ...citySearchRefs,
      [newId]: null,
    });
  };

  const fetchDeliverySettingsData = async () => {
    setInitLoading(true);
    try {
      await fetchTaxProfiles();
      const res = await axios.get("/v1/delivery/get");
      const data = res.data.data;
      setInput({
        ...data,
        exceptionZipcodes: data.exceptionZipcodes.reduce(
          (acc, curr) => (acc !== "" ? `${acc},${curr}` : curr),
          ""
        ),
      });
      setDeliveryTimeStructure(data.deliveryTimeStructure);
    } catch (error) {
      setFeedback({
        isOpen: true,
        isSuccess: false,
        message: "Failed to fetch data",
      });
    }
    setInitLoading(false);
  };
  const updateDeliverySettings = async () => {
    const errors = [];
    if (input.deliveryEnabled) {
      if (input.radius <= 0) {
        errors.push("Radius must be higher than zero");
      }
      if (!input.taxProfile) {
        errors.push("Tax profile must be selected");
      }
      if (input.chargeIncreamentByDistanceEnabled) {
        if (!input.increamentPerMiles || input.increamentPerMiles <= 0) {
          errors.push(
            "Increament amount per mile has to be positive and more than zero"
          );
        }
      }
      if (
        !input.customDeliveryCharges.reduce((acc, curr) => {
          if (!curr.city) return false;
          if (!curr.deliveryCharge || curr.deliveryCharge <= 0) return false;
          return true;
        }, true)
      ) {
        errors.push(
          "City and positive delivery charge is mandetory for all Custom delivery charge entries!"
        );
      }
    }

    if (errors.length !== 0) {
      setFeedback({
        isOpen: true,
        isSuccess: false,
        message: (
          <ul>
            {errors.map((err) => (
              <li>{err}</li>
            ))}
          </ul>
        ),
      });
      return;
    }

    setSubmitLoading(true);
    const body = {
      ...input,
      customDeliveryCharges: input.customDeliveryCharges.map((charge) => {
        if (charge.taxProfile === "parent") {
          return {
            ...charge,
            taxProfile: input.taxProfile,
          };
        }
        return charge;
      }),
      exceptionZipcodes: input.exceptionZipcodes
        .split(",")
        .map((zip) => zip.trim()),
    };
    try {
      await axios.put("/v1/delivery/update", body);
      setFeedback({
        isOpen: true,
        isSuccess: true,
        message: "Delivery settings updated successfully",
      });
    } catch (error) {
      setFeedback({
        isOpen: true,
        isSuccess: false,
        message: "Something went wrong! Failed to update delivery settings",
      });
    }
    setSubmitLoading(false);
  };
  const updateDeliveryTiming = async () => {
    const errors = [];
    if (!(deliveryTimeStructure.orderLimitPerSlot > 0)) {
      errors.push("Per slot order limit cannot be negative or 0");
    }

    if (!(deliveryTimeStructure.orderPlaceMaximumHour >= 1)) {
      errors.push("Maxium hour for ordering should be atleast 1 hour");
    }
    if (deliveryTimeStructure.priorOrderBufferTimeInMinutes < 0) {
      errors.push("Prior order buffer time cannot be negative");
    }
    const validation = validateDeliveryTimeStructure(deliveryTimeStructure);
    if (!validation.success) {
      errors.push(validation.message);
    }
    if (errors.length !== 0) {
      setFeedback({
        isOpen: true,
        isSuccess: false,
        message: (
          <ul>
            {errors.map((err) => (
              <li>{err}</li>
            ))}
          </ul>
        ),
      });
      return;
    }
    setSubmitLoading(true);
    const body = {
      deliveryTimeStructure,
    };
    try {
      await axios.put("/v1/delivery/update-delivery-timing", body);
      setFeedback({
        isOpen: true,
        isSuccess: true,
        message: "Delivery settings updated successfully",
      });
    } catch (error) {
      setFeedback({
        isOpen: true,
        isSuccess: false,
        message: "Something went wrong! Failed to update delivery timing",
      });
    }
    setSubmitLoading(false);
  };

  const fetchTaxProfiles = async () => {
    const res = await axios.get("/v1/tax-profile/list");
    setTaxProfiles([
      ...res.data.data.defaultTaxProfiles,
      ...res.data.data.customTaxProfiles,
    ]);
  };
  const handleDeleteCharge = (id) => {
    setInput({
      ...input,
      customDeliveryCharges: input.customDeliveryCharges.filter(
        (charge) => charge.id !== id
      ),
    });
  };
  /**
   * Validates the delivery time structure.
   * @param {IUpdateDeliveryTimeStructureBody} data - The data to validate.
   * @returns {boolean} - True if valid, false otherwise.
   */
  function validateDeliveryTimeStructure(deliveryTimeStructure) {
    const WEEKDAYS = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
    ];
    if (!deliveryTimeStructure || !deliveryTimeStructure.slots) {
      return {
        message: `Slot list cannot be null`,
        success: false,
      };
    }

    const slots = deliveryTimeStructure.slots;

    for (let day = 0; day < slots.length; day++) {
      const daySlots = slots[day];

      // Convert slot times to minutes for easy comparison
      const convertedSlots = daySlots.map(({ from, to }) => ({
        from: from ? convertTimeToMinutes(from) : null,
        to: to ? convertTimeToMinutes(to) : null,
      }));

      if (convertedSlots.some(({ from, to }) => from === null || to === null)) {
        return {
          message: `Error: "from" or "to" is empty on ${WEEKDAYS[day]}`,
          success: false,
        };
      }

      // 1. Check if each slot's "from" time is less than the "to" time
      if (convertedSlots.some(({ from, to }) => from >= to)) {
        console.error(
          `Error: "from" time must be less than "to" time on day ${WEEKDAYS[day]}`
        );
        return {
          message: `Error: "from" time must be less than "to" time on day ${WEEKDAYS[day]}`,
          success: false,
        };
      }

      // 2. Check for overlapping slots within the same day
      for (let i = 0; i < convertedSlots.length; i++) {
        for (let j = i + 1; j < convertedSlots.length; j++) {
          if (
            (convertedSlots[i].from < convertedSlots[j].to &&
              convertedSlots[i].to > convertedSlots[j].from) ||
            (convertedSlots[j].from < convertedSlots[i].to &&
              convertedSlots[j].to > convertedSlots[i].from)
          ) {
            console.error(
              `Error: Overlapping slots found on day ${WEEKDAYS[day]}`
            );
            return {
              message: `Error: Overlapping slots found on day ${WEEKDAYS[day]}`,
              success: false,
            };
          }
        }
      }
    }

    // All checks passed
    return { success: true };
  }

  /**
   * Converts time from "hh:mm" format to minutes.
   * @param {string} time - Time in "hh:mm" format.
   * @returns {number} - Time in minutes.
   */
  function convertTimeToMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }
  return !initLoading ? (
    <ContentContainer heading={"Delivery Settings"}>
      <Grid container spacing={3}>
        {feedback.isOpen && (
          <Grid item xs={12}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setFeedback({
                      ...feedback,
                      isOpen: false,
                    });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity={feedback.isSuccess ? "success" : "error"}
            >
              {feedback.message}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={input.deliveryEnabled}
              onChange={(e) =>
                handleInputChange("deliveryEnabled", e.target.checked)
              }
            />
            <span>Enable/Disable Delivery</span>
          </div>
        </Grid>
        {input.deliveryEnabled && (
          // <DeliverySettingsInfo
          //   input={input}
          //   handleRadiusInputChange={handleRadiusInputChange}
          //   handleInputChange={handleInputChange}
          //   citySearchRefs={citySearchRefs}
          //   citySearchRefsRef={citySearchRefsRef}
          //   setCitySearchRefs={setCitySearchRefs}
          //   handleDeleteCharge={handleDeleteCharge}
          //   taxProfiles={taxProfiles}
          //   handleAddNewCustomDeliveryCharge={handleAddNewCustomDeliveryCharge}
          // />
          <>
            <Grid item xs={12} lg={6}>
              <Tabview
                tabs={[
                  {
                    label: "Info",
                    id: "info",
                  },
                  {
                    label: "Delivery Time Settings",
                    id: "timing",
                  },
                ]}
                onChangeTab={(tab) => setCurrentTab(tab.id)}
              />
            </Grid>
            {currentTab === "info" && (
              <DeliverySettingsInfo
                input={input}
                handleRadiusInputChange={handleRadiusInputChange}
                handleInputChange={handleInputChange}
                citySearchRefs={citySearchRefs}
                citySearchRefsRef={citySearchRefsRef}
                setCitySearchRefs={setCitySearchRefs}
                handleDeleteCharge={handleDeleteCharge}
                taxProfiles={taxProfiles}
                handleAddNewCustomDeliveryCharge={
                  handleAddNewCustomDeliveryCharge
                }
              />
            )}
            {currentTab === "timing" && (
              <Grid item xs={12}>
                <DeliverySettingsTime
                  data={deliveryTimeStructure}
                  onChange={setDeliveryTimeStructure}
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={submitLoading}
            onClick={
              currentTab === "info"
                ? updateDeliverySettings
                : updateDeliveryTiming
            }
          >
            {currentTab === "info" ? "Update Info" : "Update Timing"}
          </Button>
        </Grid>
      </Grid>
    </ContentContainer>
  ) : (
    <FormSkeletalLoader />
  );
}

export default DeliverySettings;
