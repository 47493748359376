import React, { useEffect } from "react";
import ContentContainer from "../../atoms/ContentContainer";
import InputPrimary from "../../atoms/InputPrimary";
import {
  Alert,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Switch,
} from "@mui/material";
// import CustomSelectPrimary from "../../atoms/CustomSelectPrimary/CustomSelectPrimary";
// import MultipleSelectPrimary from "../../atoms/MultipleSelectPrimary";
import { useState } from "react";
import MultipleSelect from "../../atoms/MultiSelectMui";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import { generateRandomString } from "../../helpers/utils";
import axios from "../../config/axios";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DropDownCategoryPicker from "../../components/common/DropDownCategoryPicker";

function TaxProfileEditor({
  id,
  onSubmit,
  overWrfiteDefaultSubmit,
  submitBtnText,
  submitLoadingProp,
  heading,
  disableCategorySelector,
}) {
  const defaultInput = {
    _id: null,
    name: "",
    description: "",
    isApplicableToSpecificCategories: disableCategorySelector ? false : true,
    selectedCategories: [],
    taxes: [
      {
        taxName: "Default",
        taxRate: 10,
        externalId: generateRandomString(6),
        compoundTaxReferences: [],
        allowedReferences: [],
      },
    ],
  };
  const [input, setInput] = useState(defaultInput);
  const [initLoading, setInitLoading] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const history = useHistory();
  const [submitLoading, setSubmiLoading] = useState(false);
  const [feedback, setFeedback] = useState({
    isSuccess: true,
    isOpen: false,
    message: "",
  });

  useEffect(() => {
    if (id) {
      fetchInitData(id);
    }
  }, []);

  const fetchInitData = async (id) => {
    setInitLoading(true);
    try {
      const res = await axios.get(`v1/tax-profile/get?id=${id}`);
      const taxProfileData = res.data.data;
      setInput({
        ...input,
        _id: taxProfileData._id,
        isApplicableToSpecificCategories:
          taxProfileData.isApplicableToSpecificCategories,
        name: taxProfileData.name,
        description: taxProfileData.description,
        taxes: taxProfileData.taxes.map((charge) => ({
          ...charge,
          allowedReferences: taxProfileData.taxes.filter(
            (t) =>
              t.externalId !== charge.externalId &&
              t.compoundTaxReferences.length === 0
          ),
        })),
      });
      console.log("Taxes", taxProfileData.categoryIds);
      const _selectedCategory = {};
      taxProfileData.categoryIds.forEach((cat) => {
        _selectedCategory[cat.categoryID] = cat;
      });
      setSelectedCategory(_selectedCategory);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
    setInitLoading(false);
  };

  const handleInputChange = (key, value) => {
    setInput({
      ...input,
      [key]: value,
    });
  };
  const handleAddCharge = () => {
    const newTaxReference = {
      taxName: "",
      taxRate: 0,
      externalId: generateRandomString(6),
      compoundTaxReferences: [],
      allowedReferences: getAllowedReferencesForTaxProfile(),
    };
    const updatedTaxes = input.taxes.map((tax) => ({
      ...tax,
      allowedReferences: [...tax.allowedReferences, newTaxReference],
    }));
    const newInput = {
      ...input,
      taxes: [...updatedTaxes, newTaxReference],
    };

    setInput(newInput);
  };

  const getAllowedReferencesForTaxProfile = (externalId) => {
    const _id = externalId ?? null;
    return input.taxes.filter(
      (tax) => tax.compoundTaxReferences.length === 0 && tax.externalId !== _id
    );
  };

  const handleUpdateTaxProfileDataOld = (id, key, value) => {
    var newInput = input;
    if (key === "compoundTaxReferences") {
      const isEmptyReference = value.length === 0;
      newInput.taxes = newInput.taxes.map((tax) => {
        if (tax.externalId === id) return tax;
        const newCompoundTaxReference =
          tax.compoundTaxReferences.find((ref) => ref === id) &&
          !isEmptyReference
            ? tax.compoundTaxReferences.filter((ref) => ref !== id)
            : tax.compoundTaxReferences;
        const newAllowedReferences =
          tax.allowedReferences.find((ref) => ref.externalId === id) &&
          !isEmptyReference
            ? tax.compoundTaxReferences.filter((ref) => ref.externalId !== id)
            : tax.compoundTaxReferences;

        return {
          ...tax,
          compoundTaxReferences: newCompoundTaxReference,
          allowedReferences: newAllowedReferences,
        };
      });
    }
    console.log("New input", newInput);
    const _input = {
      ...newInput,
      taxes: newInput.taxes.map((t) => {
        if (t.externalId === id) {
          return {
            ...t,
            [key]: value,
          };
        }
        return {
          ...t,
          allowedReferences: t.allowedReferences.map((ref) => {
            if (ref.externalId === id) {
              return { ...ref, [key]: value };
            }
            return ref;
          }),
        };
      }),
    };
    console.log("Final input", _input);
    setInput(_input);
  };

  const handleUpdateTaxProfileData = (id, key, value) => {
    const newInput = input;
    if (key === "taxName") {
      const _input = {
        ...newInput,
        taxes: newInput.taxes.map((tax) => {
          if (tax.externalId === id) {
            return {
              ...tax,
              [key]: value,
            };
          }
          return {
            ...tax,
            allowedReferences: tax.allowedReferences.map((allowed) => {
              if (allowed.externalId === id) {
                return {
                  ...allowed,
                  [key]: value,
                };
              }
              return allowed;
            }),
          };
        }),
      };
      setInput(_input);
    } else if (key === "compoundTaxReferences") {
      const selectedTaxRow = newInput.taxes.find(
        (tax) => tax.externalId === id
      );
      const _input = {
        ...newInput,
        taxes: newInput.taxes.map((t) => {
          if (t.externalId === id) {
            return {
              ...t,
              [key]: value,
            };
          }
          if (value.length === 0) {
            return {
              ...t,
              allowedReferences: [...t.allowedReferences, selectedTaxRow],
            };
          } else {
            return {
              ...t,
              allowedReferences: t.allowedReferences.filter(
                (allowed) => allowed.externalId !== selectedTaxRow.externalId
              ),
              compoundTaxReferences: t.compoundTaxReferences.filter(
                (compound) => compound !== id
              ),
            };
          }
        }),
      };
      setInput(_input);
    } else {
      const _input = {
        ...newInput,
        taxes: newInput.taxes.map((t) => {
          if (t.externalId === id) {
            return {
              ...t,
              [key]: value,
            };
          }
          return t;
        }),
      };
      console.log("Final input", _input);
      setInput(_input);
    }
  };

  const refreshTaxCharges = (input) => {
    setInput({
      ...input,
      taxes: input.taxes.map((charge) => ({
        ...charge,
        allowedReferences: input.taxes.filter(
          (t) =>
            t.externalId !== charge.externalId &&
            t.compoundTaxReferences.length === 0
        ),
      })),
    });
  };

  const handleSubmit = async () => {
    const validationErrors = [];
    if (!input.name) {
      validationErrors.push("Name field is required");
    }
    if (
      input.isApplicableToSpecificCategories &&
      Object.keys(selectedCategory).length === 0
    ) {
      validationErrors.push(
        "Atleast one category must be selected for custom tax profiles"
      );
    }

    if (validationErrors.length !== 0) {
      setFeedback({
        isSuccess: false,
        isOpen: true,
        message: (
          <ul>
            {validationErrors.map((err) => (
              <li>{err}</li>
            ))}
          </ul>
        ),
      });
      return;
    }

    const body = {
      ...input,
      categoryIds: Object.keys(selectedCategory).map(
        (key) => selectedCategory[key]._id
      ),
    };
    if (!overWrfiteDefaultSubmit) {
      setSubmiLoading(true);
      try {
        if (id) {
          await axios.put(`v1/tax-profile/update`, body);
        } else {
          await axios.post(`v1/tax-profile/create`, body);
        }
        setFeedback({
          isSuccess: true,
          isOpen: true,
          message: "Tax created successfully",
        });
        setTimeout(() => {
          history.push("/tax-profile");
        }, 2000);
      } catch (error) {
        setFeedback({
          isSuccess: false,
          isOpen: true,
          message: "Failed to create tax profile",
        });
      }
      setSubmiLoading(false);
    }
    if (onSubmit) {
      onSubmit(body);
      setInput(defaultInput);
    }
  };

  const handleDeleteCharge = (externalId) => {
    const _input = {
      ...input,
      taxes: input.taxes
        .filter((tax) => tax.externalId !== externalId)
        .map((tax) => ({
          ...tax,
          allowedReferences: tax.allowedReferences.filter(
            (ref) => ref.externalId !== externalId
          ),
          compoundTaxReferences: tax.compoundTaxReferences.filter(
            (ref) => ref !== externalId
          ),
        })),
    };
    setInput(_input);
    refreshTaxCharges(_input);
  };

  return (
    <ContentContainer heading={heading ?? "Tax Profile Editor"}>
      <Grid container spacing={3}>
        {feedback.isOpen && (
          <Grid item xs={12}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setFeedback({
                      ...feedback,
                      isOpen: false,
                    });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity={feedback.isSuccess ? "success" : "error"}
            >
              {feedback.message}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <InputPrimary
            value={input.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            placeholder="Unique tax profile name"
            label={"Profile Name"}
          />
        </Grid>
        {!disableCategorySelector && (
          <Grid item xs={12} lg={6}>
            {input.isApplicableToSpecificCategories && (
              <DropDownCategoryPicker
                label={"Pick Categories*"}
                selected={selectedCategory}
                setSelected={(value) => setSelectedCategory(value)}
                referLocalData={true}
              />
            )}
            {/* <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Switch
              checked={input.isApplicableToSpecificCategories}
              onChange={(e) =>
                handleInputChange(
                  "isApplicableToSpecificCategories",
                  e.target.checked
                )
              }
            />
            <span>Applicable to specific categories?</span>
          </div> */}
          </Grid>
        )}
        <Grid item xs={12}>
          <InputPrimary
            value={input.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            placeholder="Describe the purpose of this tax profile"
            label={"Description"}
            mutiline={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <h6 style={{ color: "black" }}>Tax Structure</h6>
            <Button variant="contained" onClick={handleAddCharge}>
              Add Charge
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Charge Name</TableCell>
                  <TableCell>Tax Rate (%)</TableCell>
                  <TableCell>Compound Tax Reference</TableCell>
                  <TableCell width={50}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {input.taxes.map((tax, i) => (
                  <TableRow key={tax.externalId}>
                    <TableCell>
                      <InputPrimary
                        value={tax.taxName}
                        onChange={(e) =>
                          handleUpdateTaxProfileData(
                            tax.externalId,
                            "taxName",
                            e.target.value
                          )
                        }
                        placeholder={"Name of tax charge"}
                      />
                    </TableCell>
                    <TableCell>
                      <InputPrimary
                        value={tax.taxRate}
                        onChange={(e) =>
                          handleUpdateTaxProfileData(
                            tax.externalId,
                            "taxRate",
                            e.target.value
                          )
                        }
                        type="number"
                        placeholder={"Tax amount"}
                      />
                    </TableCell>
                    <TableCell>
                      <MultipleSelect
                        value={tax.compoundTaxReferences}
                        options={tax.allowedReferences.map((ref, i) => ({
                          label:
                            ref.taxName || ref.taxName !== "" ? (
                              ref.taxName
                            ) : (
                              <i>(Unnamed Tax) {i + 1}</i>
                            ),
                          value: ref.externalId,
                        }))}
                        onChange={(val) =>
                          handleUpdateTaxProfileData(
                            tax.externalId,
                            "compoundTaxReferences",
                            val
                          )
                        }
                      />
                    </TableCell>
                    {i !== 0 && (
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteCharge(tax.externalId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={submitLoading || submitLoadingProp}
            variant="contained"
            onClick={handleSubmit}
          >
            {submitBtnText ?? (id ? "Update" : "Create")}
          </Button>
        </Grid>
      </Grid>
    </ContentContainer>
  );
}

export default TaxProfileEditor;
