import React, { useEffect, useState } from "react";
import TaxProfileEditor from "./TaxProfileEditor";
import axios from "../../config/axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function DefaultTaxProfileSetup() {
  // currentScreen: "regular" | "cannabis"
  const history = useHistory();
  const [currentScreen, setCurrentScreen] = useState("regular");
  const [regularTaxProfileData, setRegularTaxProfileData] = useState(null);
  const [cannabisTaxProfileData, setCannabisTaxProfileData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const handleRegularTaxProfileSubmit = (body) => {
    setRegularTaxProfileData(body);
    setCurrentScreen("cannabis");
  };
  useEffect(() => {
    fetchTaxProfiles();
  }, [])
  const fetchTaxProfiles = async () => {
    setInitLoading(true);
    try {
      const res = await axios.get("/v1/tax-profile/list");
      if (res.data.data.defaultTaxProfiles.length) {
        history.push('/tax-profile');
      }
    } catch (error) {
      console.log("Failed to load data", error);
    }
    setInitLoading(false);
  };
  const handleCannabisTaxProfileSubmit = async (body) => {
    setCannabisTaxProfileData(body);
    const _body = {
        regular: regularTaxProfileData,
        cannabis: body
    }
    setSubmitLoading(true);
    try {
        await axios.post('/v1/tax-profile/setup', _body);
        history.push('/tax-profile');
    } catch (error) {
        console.log("Failed to setup tax profile", error);
    }
    setSubmitLoading(false);
  }
  return currentScreen === "regular" ? (
    <TaxProfileEditor
      heading={"Setup Regular Tax Profile"}
      overWrfiteDefaultSubmit={true}
      onSubmit={handleRegularTaxProfileSubmit}
      submitBtnText={"Next"}
      disableCategorySelector={true}
      submitLoadingProp={submitLoading}
    />
  ) : (
    <TaxProfileEditor
      heading={"Setup Cannabis Tax Profile"}
      overWrfiteDefaultSubmit={true}
      onSubmit={handleCannabisTaxProfileSubmit}
      submitBtnText={"Finish"}
      disableCategorySelector={true}
      submitLoadingProp={submitLoading}
    />
  );
}

export default DefaultTaxProfileSetup;
