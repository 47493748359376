import React from "react";
import {
  Grid,
  Divider,
  Chip,
  Switch,
  Button,
  Alert,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InputPrimary from "../../atoms/InputPrimary";
import CustomSelectPrimary from "../../atoms/CustomSelectPrimary/CustomSelectPrimary";

function DeliverySettingsInfo({
  input,
  handleRadiusInputChange,
  handleInputChange,
  citySearchRefsRef,
  setCitySearchRefs,
  handleDeleteCharge,
  taxProfiles,
  handleAddNewCustomDeliveryCharge,
}) {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} lg={6}>
        <InputPrimary
          // hintChip={<span style={{ margin: '0px 10px' }}>miles</span>}
          label={"Deivery Radius (in miles)"}
          placeholder="Enter delivery radius to allow delivery"
          type="number"
          value={input.radius}
          onChange={(e) => handleRadiusInputChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <InputPrimary
          hintChip={"$"}
          label={"Base Delivery Charge"}
          placeholder="Define your base delivery charge"
          type="number"
          value={input.deliveryCharge}
          onChange={(e) => handleInputChange("deliveryCharge", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <InputPrimary
          // hintChip={<span style={{ margin: '0px 10px' }}>miles</span>}
          label={"Enter exception zip codes"}
          placeholder="Enter zip codes where you will not make delivery"
          value={input.exceptionZipcodes}
          onChange={(e) =>
            handleInputChange("exceptionZipcodes", e.target.value)
          }
        />
        <div
          style={{
            marginTop: 10,
            display: "flex",
            flexWrap: "wrap",
            gap: 5,
          }}
        >
          {input.exceptionZipcodes
            .split(",")
            .map((zipCode) => zipCode.trim())
            .map((zipCode, i) =>
              zipCode !== "" ? (
                <Chip key={`${zipCode}_${i}`} label={zipCode} />
              ) : (
                ""
              )
            )}
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomSelectPrimary
          value={input.taxProfile}
          setValue={(value) => handleInputChange("taxProfile", value)}
          label={"Select Tax Profile"}
          inputLabel={"No tax profile selected"}
          options={taxProfiles.map((profile) => ({
            value: profile._id,
            label: profile.name,
          }))}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <InputPrimary
          hintChip={"$"}
          label={"Minimum Order Amount"}
          placeholder="Define what is the minimum user can order for delivery"
          type="number"
          value={input.minimumOrderAmount}
          onChange={(e) => handleInputChange("minimumOrderAmount", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Switch
            checked={input.chargeIncreamentByDistanceEnabled}
            onChange={(e) =>
              handleInputChange(
                "chargeIncreamentByDistanceEnabled",
                e.target.checked
              )
            }
          />
          <span>Enable charge increament by distance</span>
        </div>
      </Grid>
      {input.chargeIncreamentByDistanceEnabled && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputPrimary
              value={input.maxDistanceForBaseDeliveryCharge}
              onChange={(e) =>
                handleInputChange(
                  "maxDistanceForBaseDeliveryCharge",
                  e.target.value
                )
              }
              label="Apply increamental charge after distance (in miles)"
              type={"number"}
              placeholder="Input distance in miles"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputPrimary
              value={input.increamentPerMiles}
              onChange={(e) =>
                handleInputChange("increamentPerMiles", e.target.value)
              }
              label="Increament amount per miles"
              type={"number"}
              hintChip={"$"}
              placeholder="Input increament amount"
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h6>Custom Rate By Cities</h6>
          <Button
            variant="contained"
            onClick={handleAddNewCustomDeliveryCharge}
          >
            Add New Entry
          </Button>
        </div>
      </Grid>
      {input.chargeIncreamentByDistanceEnabled && (
        <Grid item xs={12}>
          <Alert severity="warning">
            By adding charges by custom city, it will overwrite your specified
            increament by distance with your specified charge
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <TableContainer style={{ minHeight: 300 }} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>City</TableCell>
                <TableCell>Delivery Rate</TableCell>
                <TableCell>Tax Profile</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {input.customDeliveryCharges.map((charge) => (
                <TableRow key={charge.id}>
                  <TableCell>
                    {/* <CustomSelectPrimary
                            //   value={input.taxProfile}
                            //   setValue={(value) =>
                            //     handleInputChange("taxProfile", value)
                            //   }
                            value={charge.city}
                            inputLabel={"No city selected"}
                            options={eligibleCities.map((city) => ({
                              label: city.city,
                              value: city.id,
                            }))}
                          /> */}
                    <InputPrimary
                      placeholder="Search cities in defined radius"
                      defaultValue={charge.city.formatted_address}
                      ref={(ref) => {
                        if (!citySearchRefsRef.current[charge.id]) {
                          const _citySearchRefs = {
                            ...citySearchRefsRef.current,
                            [charge.id]: ref,
                          };
                          setCitySearchRefs(_citySearchRefs);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <InputPrimary
                      placeholder="Enter charge"
                      value={charge.deliveryCharge}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomSelectPrimary
                      // value={input.taxProfile}
                      // setValue={(value) =>
                      //   handleInputChange("taxProfile", value)
                      // }
                      value={charge.taxProfile}
                      inputLabel={"No tax profile selected"}
                      options={[
                        { label: "Parent", value: "parent" },
                        ...taxProfiles.map((t) => ({
                          value: t._id,
                          label: t.name,
                        })),
                      ]}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleDeleteCharge(charge.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}

export default DeliverySettingsInfo;
