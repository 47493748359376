import React from 'react'
import TaxProfileEditor from './TaxProfileEditor'
import { useParams } from 'react-router-dom'

function EditTaxProfile() {
    const { id } = useParams();
  return (
    <TaxProfileEditor id={id} />
  )
}

export default EditTaxProfile