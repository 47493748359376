import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton, Box } from "@mui/material";

const TableSkeletalLoader = () => {
  // Number of rows and columns for the skeleton table
  const rows = 5;
  const columns = 4;

  return (
    <TableContainer component={Box}>
      <Table>
        <TableHead>
          <TableRow>
            {Array(columns)
              .fill("")
              .map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant="text" />
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(rows)
            .fill("")
            .map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {Array(columns)
                  .fill("")
                  .map((_, colIndex) => (
                    <TableCell key={colIndex}>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeletalLoader;
