import React from "react";
import styled from "@emotion/styled";
import { Button, useTheme } from "@mui/material";

/**
 * tabs = [
 *  { id: "unique_id" , label: "Tab1", component: <ChildComponentAtSpecificTab /> }
 * ]
 */
function Tabview({
  tabs,
  onChangeTab = null,
  defaultTabIndex = 0,
  color = "primary",
}) {
  const theme = useTheme();
  const sliderRef = React.useRef();
  const [currentTab, setCurrentTab] = React.useState(
    tabs ? tabs[defaultTabIndex] : null
  );
  const tabGap = 5;
  const switchTab = (event, tab, index) => {
    sliderRef.current.style.transform = `translateX(${
      index * tabGap + index * event.target.offsetWidth
    }px)`;
    setCurrentTab(tab);
    if (onChangeTab) {
      onChangeTab(tab);
    }
  };
  return (
    <Wrapper
      theme={theme}
      totalTabs={tabs ? tabs.length : 1}
      tabGap={tabGap}
      color={color}
    >
      <div className="switcher">
        <div className="switch-slider" ref={sliderRef}></div>
        {tabs.map((tab, i) => (
          <Button
            key={tab.id} 
            onClick={(e) => switchTab(e, tab, i)}
            className={currentTab.id === tab.id && "selected"}
          >
            {tab.label}
          </Button>
        ))}
      </div>
      {tabs.map((tab) => (
        <div
          className={`tab-content ${currentTab.id === tab.id && "selected"}`}
          key={tab.id}
        >
          {tab.component}
        </div>
      ))}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .switcher {
    position: relative;
    padding: 6px;
    border-radius: 5px;
    border: 1.15px solid #e2e8f0;
    display: flex;
    gap: ${(props) => props.tabGap}px;
    .switch-slider {
      position: absolute;
      border-radius: 5px;
      background-color: ${(props) =>
        props.color === "primary"
          ? props.theme.palette.primary.main
          : props.color === "secondary"
          ? props.theme.palette.secondary.main
          : props.color};
      left: 6px;
      top: 6px;
      height: calc(100% - 12px);
      width: calc(${(props) => 100 / props.totalTabs}% - 12px);
      /* width: calc(${(props) => 100 / props.totalTabs}% - ${(props) =>
        (props.totalTabs - 1) * props.tabGap}px); */
      transition: transform 0.3s ease-out;
    }
    button {
      flex: 1;
      z-index: 2;
      box-sizing: border-box;
      &.selected {
        color: white;
      }
    }
  }
  .tab-content {
    display: none;
    margin-top: 25px;
    &.selected {
      display: block;
    }
  }
`;

export default Tabview;
