import React, { useEffect, useState } from "react";
import { Grid, Button, IconButton, Alert } from "@mui/material";
import Tabview from "../../atoms/Tabview/Tabview";
import InputPrimary from "../../atoms/InputPrimary";
import NoData from "../../atoms/Placeholders/NoData";
// import dayjs from "dayjs";
import TimePicker from "../../atoms/DatePicker/TimePicker";
import TimePickerDesktop from "../../atoms/DatePicker/TimePickerDesktop";
import {
  AddOutlined,
  DeleteOutline,
  PlusOneOutlined,
} from "@mui/icons-material";
import moment from "moment";
import ConfirmationDialog from "../../atoms/Dialogs/ConfirmationDialog";

const INITIAL_TIME_STRUCTURE = {
  priorOrderBufferTimeInMinutes: 30,
  orderPlaceMaximumHour: 48,
  orderLimitPerSlot: 100,
  slots: [[], [], [], [], [], [], []],
};

function DeliverySettingsTime({ data, onChange }) {
  const [deliveryTimeStructure, setDeliveryTimeStructure] = useState(data);
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [slotValidation, setSlotValidation] = useState({
    success: false,
    message: "",
  });
  const [stagedSlotIndexForCopy, setStagedSlotIndexForCopy] = useState(null);
  const [isSlotCopyConfirmationDialogOpen, setIsSlotCopyConfirmationDialogOpen] = useState(false);

  /**
   * Lifecycle functions
   */
  useEffect(() => {
    if (onChange) onChange(deliveryTimeStructure);
    if (deliveryTimeStructure) {
      setSlotValidation(validateDeliveryTimeStructure(deliveryTimeStructure));
    }
  }, [deliveryTimeStructure]);
  /**
   * ====== End Lifecycle functions
   */

  /**
   * Handler functions
   */
  const handleAddSlot = (index) => {
    setDeliveryTimeStructure({
      ...deliveryTimeStructure,
      slots: deliveryTimeStructure.slots.map((day, i) => {
        if (i === index) {
          return [
            ...day,
            {
              from: null,
              to: null,
            },
          ];
        }
        return day;
      }),
    });
  };
  const handleCopySlotToAllDaysClick = (dayIndex) => {
    setIsSlotCopyConfirmationDialogOpen(true);
    setStagedSlotIndexForCopy(dayIndex);
  }
  const handleSlotChange = (dayIndex, slotIndex, key, value) => {
    const timeString = moment(new Date(value)).format("HH:mm");

    setDeliveryTimeStructure({
      ...deliveryTimeStructure,
      slots: deliveryTimeStructure.slots.map((day, i) => {
        if (i === dayIndex) {
          return day.map((slot, si) => {
            if (si === slotIndex) {
              return {
                ...slot,
                [key]: timeString,
              };
            }
            return slot;
          });
        }
        return day;
      }),
    });
  };
  const handleSlotDelete = (dayIndex, slotIndex) => {
    setDeliveryTimeStructure({
      ...deliveryTimeStructure,
      slots: deliveryTimeStructure.slots.map((day, i) => {
        if (i === dayIndex) {
          return day.filter((slot, si) => si !== slotIndex);
        }
        return day;
      }),
    });
  };
  /**
   * ======== Handler functions end =========
   */

  /**
   * Action functions
   */
  const copySameSlotsForAllDays = (dayIndex) => {
    const slots = deliveryTimeStructure.slots[dayIndex];
    setDeliveryTimeStructure({
      ...deliveryTimeStructure,
      slots: deliveryTimeStructure.slots.map(slot => slots)
    })

  }
  /**
   * ====== End action functions
   */

  /**
   * Helper functions
   */
  /**
   * Validates the delivery time structure.
   * @param {IUpdateDeliveryTimeStructureBody} data - The data to validate.
   * @returns {boolean} - True if valid, false otherwise.
   */
  function validateDeliveryTimeStructure(deliveryTimeStructure) {
    const WEEKDAYS = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
    ];
    if (!deliveryTimeStructure || !deliveryTimeStructure.slots) {
      return {
        message: `Slot list cannot be null`,
        success: false,
      };
    }

    const slots = deliveryTimeStructure.slots;

    for (let day = 0; day < slots.length; day++) {
      const daySlots = slots[day];

      // Convert slot times to minutes for easy comparison
      const convertedSlots = daySlots.map(({ from, to }) => ({
        from: from ? convertTimeToMinutes(from) : null,
        to: to ? convertTimeToMinutes(to) : null,
      }));

      if (
        !convertedSlots.some(({ from, to }) => from === null || to === null)
      ) {
        // 1. Check if each slot's "from" time is less than the "to" time
        if (convertedSlots.some(({ from, to }) => from >= to)) {
          console.error(
            `Error: "from" time must be less than "to" time on day ${WEEKDAYS[day]}`
          );
          return {
            message: `Error: "from" time must be less than "to" time on day ${WEEKDAYS[day]}`,
            success: false,
          };
        }

        // 2. Check for overlapping slots within the same day
        for (let i = 0; i < convertedSlots.length; i++) {
          for (let j = i + 1; j < convertedSlots.length; j++) {
            if (
              (convertedSlots[i].from < convertedSlots[j].to &&
                convertedSlots[i].to > convertedSlots[j].from) ||
              (convertedSlots[j].from < convertedSlots[i].to &&
                convertedSlots[j].to > convertedSlots[i].from)
            ) {
              console.error(
                `Error: Overlapping slots found on day ${WEEKDAYS[day]}`
              );
              return {
                message: `Error: Overlapping slots found on day ${WEEKDAYS[day]}`,
                success: false,
              };
            }
          }
        }
      }
    }

    // All checks passed
    return { success: true };
  }

  /**
   * Converts time from "hh:mm" format to minutes.
   * @param {string} time - Time in "hh:mm" format.
   * @returns {number} - Time in minutes.
   */
  function convertTimeToMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }
  /**
   * ======== End helper functions =========
   */
  return deliveryTimeStructure ? (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <InputPrimary 
            label="Timezone"
            value={deliveryTimeStructure.timezone}
            disabled={true}
        />
      </Grid> */}
      <Grid item xs={6}>
        <InputPrimary
          label="Prior Order Buffer Time (in minute)"
          help={
            "Prior order buffer time is an important option to prevent user to place order right before delivery time slot. For example, you have slot from 8AM-9PM, you should not let user place order at time as close as 7:50am, because you need time to pack and dispatch the product. It is recommended to keep sufficient time in buffer time for packing and delivering for best customer experience"
          }
          value={deliveryTimeStructure.priorOrderBufferTimeInMinutes}
        />
      </Grid>
      <Grid item xs={6}>
        <InputPrimary
          label="Maximum Allowed Hours to Place Future Order"
          help={
            "With this option you can place the contraint before how many hour user can place online order, for example if user places order 1 month before, it is very inconveinient to stock a product for one month. Some product will also be expired by then"
          }
          value={deliveryTimeStructure.orderPlaceMaximumHour}
        />
      </Grid>
      <Grid item xs={6}>
        <InputPrimary
          label="Order Limit Per Slot"
          value={deliveryTimeStructure.orderLimitPerSlot}
        />
      </Grid>
      {!slotValidation.success && (
        <Grid item xs={12}>
          <Alert severity="error">{slotValidation.message}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Tabview
          color="secondary"
          tabs={[
            { label: "Sunday", id: 0 },
            { label: "Monday", id: 1 },
            { label: "Tuesday", id: 2 },
            { label: "Wednesday", id: 3 },
            { label: "Thursday", id: 4 },
            { label: "Friday", id: 5 },
            { label: "Saturday", id: 6 },
          ]}
          onChangeTab={(tab) => setCurrentDayIndex(tab.id)}
        />
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            gap: 8,
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            startIcon={<AddOutlined />}
            onClick={() => handleAddSlot(currentDayIndex)}
          >
            Add New
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleCopySlotToAllDaysClick(currentDayIndex)}
          >
            Copy Slot to All Days
          </Button>
        </div>
        <SlotForm
          currentDayIndex={currentDayIndex}
          slots={deliveryTimeStructure.slots[currentDayIndex]}
          handleSlotChange={handleSlotChange}
          handleSlotDelete={handleSlotDelete}
          copySameSlotsForAllDays={copySameSlotsForAllDays}
          stagedSlotIndexForCopy={stagedSlotIndexForCopy}
          isSlotCopyConfirmationDialogOpen={isSlotCopyConfirmationDialogOpen}
          setIsSlotCopyConfirmationDialogOpen={setIsSlotCopyConfirmationDialogOpen}
        />
      </Grid>
    </Grid>
  ) : (
    <div>
      <NoData
        heading="Setup Delivery Timing"
        description="Seems like there is no delivery timing setup for this shop. Start setting up delivery timing to gain more control over delivery order placement timing and serve your customer better!"
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={() => setDeliveryTimeStructure(INITIAL_TIME_STRUCTURE)}
        >
          Create
        </Button>
      </div>
    </div>
  );
}

function SlotForm({
  currentDayIndex,
  slots,
  handleSlotChange,
  handleSlotDelete,
  copySameSlotsForAllDays,
  stagedSlotIndexForCopy,
  isSlotCopyConfirmationDialogOpen,
  setIsSlotCopyConfirmationDialogOpen
}) {
  /**
   * Helper functions
   */
  const convertTimeStringToDate = (timeString) => {
    if (!timeString) return null;
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  /**
   * ====== End Helper functions ======
   */
  return slots.map((slot, si) => (
    <Grid container spacing={2} marginY={1} key={si}>
      <Grid item xs={5.5}>
        <TimePickerDesktop
          label="From"
          value={convertTimeStringToDate(slot.from)}
          onChange={(value) =>
            handleSlotChange(currentDayIndex, si, "from", value)
          }
        />
      </Grid>
      <Grid item xs={5.5}>
        <TimePickerDesktop
          label="To"
          value={convertTimeStringToDate(slot.to)}
          onChange={(value) =>
            handleSlotChange(currentDayIndex, si, "to", value)
          }
        />
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          color="error"
          style={{ marginTop: 20 }}
          onClick={() => handleSlotDelete(currentDayIndex, si)}
        >
          <DeleteOutline />
        </IconButton>
      </Grid>
      <ConfirmationDialog 
        open={isSlotCopyConfirmationDialogOpen}
        setOpen={setIsSlotCopyConfirmationDialogOpen}
        title={'Warning'}
        description={'Are you sure you want to copy this slot to all other days slot. Alert: This will replace all the existing slots of all other days with current one'}
        onCancel={() => setIsSlotCopyConfirmationDialogOpen(false)}
        onConfirm={() => {
          copySameSlotsForAllDays(stagedSlotIndexForCopy)
          setIsSlotCopyConfirmationDialogOpen(false)
        }}
      />
    </Grid>
  ));
}

export default DeliverySettingsTime;
