import { Checkbox, CircularProgress } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import SimpleModal from "../../atoms/Dialogs/SimpleModal";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import InputAreaPrimary from "../../atoms/InputAreaPrimary/Index";
import Section from "../../atoms/Section";
import ToggleSwitch from "../../atoms/ToggleSwitch";
import url from "../../config/axios";
import { fileUpload } from "../../helpers/firebaseFileUpload";
import NoCropFileUpload from "../../atoms/FileUpload/NoCropUpload";

/**
 *
 * @param {() => void} onClose close trigger function
 * @param {any} toEdit object to edit  {
    displayName: "Register",
    type: "register",
    mediums: {
      email: true,
      system: false,
      mobile: true,
      sms: false,
    },
    messages: {
      particularUser: "Welcome to Bloom",
      adminSite: "Welcome to the system",
    },
  }
 */
const initialFormData = {
  displayName: "",
  viaEmail: true,
  viaSms: true,
  viaMobile: true,
  viaSystem: true,
  title: "",
  highlights: "",
  enabled: false,
  image: "",
  mainLogoFile: null,
  mainLogoFileName: null,
  mainLogoFileSource: null,
};

const theme = createTheme({
  palette: {
    secondary: {
      // This is green.A700 as hex.
      main: "#FFA382",
    },
  },
});

function EditNotificationSettingsPopUp({
  onClose,
  toEdit = {},
  invokeLogout,
  setUpdate,
}) {
  const handleClose = (isUpdated = false) => {
    onClose({
      type: toEdit.type,
      mediums: {
        EMAIL: {
          enabled: isUpdated
            ? formData.viaEmail
            : toEdit.mediums?.EMAIL?.enabled,
          displayName: "Email",
          hidden: true,
          helperText: "Get notification via email",
        },
        IN_APP_NOTIFICATION: {
          enabled: isUpdated
            ? formData.viaMobile
            : toEdit.mediums?.IN_APP_NOTIFICATION?.enabled,
          displayName: "In-app notification",
          hidden: false,
          helperText: "Get notification via in-app notification",
        },
        PUSH_NOTIFICATION: {
          enabled: isUpdated
            ? formData.viaSystem
            : toEdit.mediums?.PUSH_NOTIFICATION?.enabled,
          displayName: "Push Notification",
          hidden: false,
          helperText: "Get push notification",
        },
        SMS: {
          enabled: isUpdated ? formData.viaSms : toEdit.mediums?.SMS?.enabled,
          displayName: "SMS",
          hidden: true,
          helperText: "Get notification via sms",
        },
      },
      displayName: isUpdated ? formData.displayName : toEdit.displayName,
      enabled: isUpdated ? formData.enabled : toEdit.enabled,
      messages: {
        userMessage: {
          enabled: true,
          highlights: isUpdated
            ? formData.highlights
            : toEdit.messages?.userMessage?.highlights,
          title: isUpdated
            ? formData.title
            : toEdit.messages?.userMessage?.title,
          link: null,
          image: toEdit.messages?.userMessage?.image ?? "",
        },
      },
    });
  };
  const [formData, setFormData] = useState({ ...initialFormData });
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [imageUrl, setImageURL] = useState("");
  const [mainLogo, setMainLogo] = useState({ file: null, source: null });

  useEffect(
    () => {
      setFormData({
        displayName: toEdit.displayName,
        viaEmail: !!toEdit.mediums?.EMAIL?.enabled,
        viaSms: !!toEdit.mediums?.SMS?.enabled,
        viaMobile: !!toEdit.mediums?.IN_APP_NOTIFICATION?.enabled,
        viaSystem: !!toEdit.mediums?.PUSH_NOTIFICATION?.enabled,
        title: toEdit.messages?.userMessage?.title ?? "",
        highlights: toEdit.messages?.userMessage?.highlights ?? "",
        image: toEdit.messages?.userMessage?.image ?? "",
        enabled: toEdit?.enabled,
        mainLogoFileSource: toEdit.messages?.userMessage?.image ?? null,
      });
      setImageURL(toEdit.messages?.userMessage?.image ?? "");
    },
    //eslint-disable-next-line
    []
  );

  const handleChangeFromEvent = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  /**
   *
   * @param {string} propName property to change
   * @param { any } value corresponding value
   */
  const handleChange = (propName, value) => {
    setFormData({ ...formData, [propName]: value });
  };

  const [formValidationErrors, setFormValidationsErrors] = useState({});
  const [triedBefore, setTriedBefore] = useState(false);
  const validateForm = () => {
    let data = { ...formValidationErrors };

    //manipulate display name
    if (formData.displayName?.trim().length === 0) {
      data = { ...data, dnError: "Name of the notification event is required" };
    } else {
      let { dnError, ...others } = data;
      data = { ...others };
    }

    //manipulate message for particular user
    if (formData.title?.trim().length === 0) {
      data = { ...data, pmError: "Message for particular user is required" };
    } else {
      let { pmError, ...others } = data;
      data = { ...others };
    }

    //manipulate admin site message
    if (formData.highlights?.trim().length === 0) {
      data = { ...data, amError: "Highlights is required...." };
    } else {
      let { amError, ...others } = data;
      data = { ...others };
    }

    setFormValidationsErrors({ ...data });
    return data;
  };

  useEffect(
    () => {
      if (triedBefore) {
        validateForm();
      }
    },
    //eslint-disable-next-line
    [formData, triedBefore]
  );

  const updateNotficationModule = async () => {
    let topicValue = "";
    if (formData.displayName === "On completing sign up") {
      topicValue = "register";
    }
    if (formData.displayName === "On cancelling order") {
      topicValue = "orderCancelled";
    }
    if (formData.displayName === "On dispatching order") {
      topicValue = "orderDispatched";
    }
    if (formData.displayName === "On completing order") {
      topicValue = "orderCompleted";
    }
    if (formData.displayName === "On approving an order") {
      topicValue = "orderProcessing";
    }
    if (formData.displayName === "On placing new order") {
      topicValue = "newOrder";
    }
    if (formData.displayName === "Others") {
      topicValue = "others";
    }
    if (formData.displayName === "On awarding loyalty points") {
      topicValue = "loyaltyPointsEarned";
    }
    if (formData.displayName === "On updating loyalty points") {
      topicValue = "loyaltyPointsUpdated";
    }
    if (formData.displayName === "On adding new coupon") {
      topicValue = "newCoupon";
    }
    if (formData.displayName === "On adding new deal") {
      topicValue = "newDeal";
    }
    let currentMainLogoFileSource = imageUrl;
    if (formData.mainLogoFile) {
      let url = await fileUpload(formData.mainLogoFile);
      if (!!url) {
        currentMainLogoFileSource = url;
      }
    } else {
      if (
        !formData.mainLogoFileSource ||
        formData.mainLogoFileSource.length === 0
      )
        currentMainLogoFileSource = "";
    }
    setTriedBefore(true);
    let errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setInProgress(true);
      let body = {
        topic: topicValue,
        enabled: formData.enabled,
        mediums: {
          PUSH_NOTIFICATION: {
            enabled: formData.viaSystem,
          },
          IN_APP_NOTIFICATION: {
            enabled: formData.viaMobile,
          },
        },
        userMessage: {
          title: formData.title,
          highlights: formData.highlights,
          image: currentMainLogoFileSource,
        },
      };
      url
        .put(`/v1/consumer-notification-settings/update-topic`, body)
        .then((res) => {
          if (res?.data?.Message === "Success") {
            setSuccess(res?.data?.Message);
            setUpdate(true);
          }
          setInProgress(false);
          handleClose(true);
        })
        .catch((e) => {
          setError(e.response.data.data.message);
          if (+e?.response?.status === 401) {
            invokeLogout();
          }
          setInProgress(false);
        });
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      displayName: toEdit.displayName,
      viaEmail: !!toEdit.mediums?.EMAIL?.enabled,
      viaSms: !!toEdit.mediums?.SMS?.enabled,
      viaMobile: !!toEdit.mediums?.IN_APP_NOTIFICATION?.enabled,
      viaSystem: !!toEdit.mediums?.PUSH_NOTIFICATION?.enabled,
      title: toEdit.messages?.userMessage?.title ?? "",
      highlights: toEdit.messages?.userMessage?.highlights ?? "",
      image: toEdit.messages?.userMessage?.image ?? "",
      enabled: toEdit?.enabled,
      mainLogoFile: mainLogo.file,
      mainLogoFileSource: mainLogo.source,
    });
  }, [mainLogo]);

  return (
    <div
      style={{
        height: "80vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}>
      <div
        className="row d-flex"
        style={{
          display: "flex",
          padding: "1em 1.5em 0.2em 1.5em",
          height: "auto",
          alignItems: "center",
          borderBottom: "1px solid rgba(241, 245, 249, 1)",
        }}>
        <div
          style={{
            height: "25px",
            marginLeft: "-9px",
            backgroundColor: "#FFA382",
            marginBottom: "12px",
          }}>
          <span style={{ color: "#FFA382" }}>.</span>
        </div>
        <div className="col-md-4">
          <p style={{ color: "#FFA382", fontWeight: "600" }}>
            Edit Notification Setting
          </p>
        </div>
        <div className="col-md-7"> </div>

        {/* <div className="col-md-1">
          <CloseIcon
            style={{ color: "#FFA382", cursor: "pointer" }}
            onClick={() => {
              if (!inProgress) {
                handleClose(false);
              }
            }}
          />
        </div> */}
      </div>

      <div
        style={{
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
        }}>
        <div className="group-comb">
          <label className="labelStyle">Event</label>

          <div
            style={{
              bordeColor: "rgba(226, 232, 240, 1) !important",
              padding: "1px 2px",
              margin: "0px",
            }}>
            <input
              type="text"
              name="displayName"
              disabled
              value={formData.displayName}
              onChange={handleChangeFromEvent}
              className="form-control"
              placeholder="Notification Event"
            />
          </div>
          {formValidationErrors.dnError ? (
            <span className="validation-help">
              {formValidationErrors.dnError}
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="group-comb">
          <label className="labelStyle">Notification Medium</label>
          <div
            style={{
              padding: "8px 10px",
              margin: "0px",
            }}>
            {/* <div
              className="row"
              style={{ alignItems: "center", marginTop: "0.5em" }}>
              <Checkbox
                disabled={true}
                checked={false}
                value={false}
                // checked={formData.viaEmail}
                // value={formData.viaEmail}
                onChange={() => handleChange("viaEmail", !formData.viaEmail)}
              />
              <span>Email</span>
            </div> */}

            <div className="row" style={{ alignItems: "center" }}>
              <ThemeProvider theme={theme}>
                <Checkbox
                  color="secondary"
                  checked={formData.viaMobile}
                  value={formData.viaMobile}
                  onChange={() =>
                    handleChange("viaMobile", !formData.viaMobile)
                  }
                />
              </ThemeProvider>
              <span>Mobile</span>
            </div>

            <div
              className="row"
              style={{ alignItems: "center", marginTop: "0.5em" }}>
              <ThemeProvider theme={theme}>
                <Checkbox
                  color="secondary"
                  checked={formData.viaSystem}
                  value={formData.viaSystem}
                  onChange={() =>
                    handleChange("viaSystem", !formData.viaSystem)
                  }
                />
              </ThemeProvider>
              <span>System</span>
            </div>

            {/* <div
              className="row"
              style={{ alignItems: "center", marginTop: "0.5em" }}>
              <Checkbox

                disabled={true}
                checked={false}
                value={false}
                // checked={formData.viaSms}
                // value={formData.viaSms}
                onChange={() => handleChange("viaSms", !formData.viaSms)}
              />
              <span>SMS</span>
            </div> */}
          </div>
        </div>

        <div className="group-comb">
          <label className="labelStyle">User Message</label>
          <div
            style={{
              bordeColor: "rgba(226, 232, 240, 1) !important",
              padding: "1px 2px",
              margin: "0px",
            }}>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChangeFromEvent}
              className="form-control"
              placeholder="Title"
            />
          </div>
          {formValidationErrors.pmError ? (
            <span className="validation-help">
              {formValidationErrors.pmError}
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="group-comb">
          <label className="labelStyle">Highlights</label>
          <div
            style={{
              bordeColor: "rgba(226, 232, 240, 1) !important",
              padding: "1px 2px",
              margin: "0px",
            }}>
            <InputAreaPrimary
              label=""
              type="text"
              name="highlights"
              rows={4}
              onChange={handleChangeFromEvent}
              placeholder="Write here"
              value={formData.highlights}
            />
          </div>
          {formValidationErrors.amError ? (
            <span className="validation-help">
              {formValidationErrors.amError}
            </span>
          ) : (
            ""
          )}
        </div>

        <div>
          <Section label="Status">
            <div style={{ transform: "translateX(-15px)" }}>
              <ToggleSwitch
                status={formData.enabled}
                onChange={() => handleChange("enabled", !formData.enabled)}
              />
            </div>
          </Section>
        </div>

        <div>
          <div
            className="fluid d-flex justify-content-center rounded py-4"
            style={{ backgroundColor: "#f9fbff" }}>
            <NoCropFileUpload
              label="Upload Notification Image"
              onUpload={setMainLogo}
              file={formData.image}
            />

            {/* <FileUpload
              file={formData.image}
              onFileRemoved={async () => {
                handleChange("image", undefined);
                handleChange("image", null);
              }}
              onFileLoaded={(file) => {
                handleChange("image", undefined);
                handleChange("image", file);
              }}
              label={"Upload Notification Image"}
            /> */}
            {/* <FileUpload
              onFileRemoved={async () => {
                handleChange("image", undefined);
                handleChange("image", null);
              }}
              onFileLoaded={(file) => {
                handleChange("image", undefined);
                handleChange("image", file);
              }}
              label={"Upload Notification Image"}
            /> */}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "1em 1.5em",
          alignItems: "center",
        }}>
        <button
          style={{
            backgroundColor: "#FFA382",
            padding: "0.6em 3em",
            cursor: "pointer",
            border: "none",
            fontWeight: "500",
            borderRadius: "5px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1em",
          }}
          onClick={updateNotficationModule}>
          {inProgress && (
            <CircularProgress style={{ color: "white" }} size={20} />
          )}
          {inProgress ? "Updating..." : "Update"}
        </button>
      </div>
      {success === true ? (
        <SimpleModal
          severity={"success"}
          highlights={"Notification updated successfully"}
          onClose={() => {
            setSuccess(null);
          }}
        />
      ) : null}
      {error ? (
        <SimpleModal
          severity={"error"}
          highlights={error}
          onClose={() => {
            setError(null);
          }}
        />
      ) : null}
    </div>
  );
}

export default EditNotificationSettingsPopUp;
