import React from 'react';
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";

const GaurdedRoutes = ({component: Component, auth, ...rest}) => {
  const history = useHistory();
    return (<Route {...rest} render={(props) => (
      auth === true
        ? <Component {...props} />
        : history.push("/")
    )} />)
}

export default GaurdedRoutes;