import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useEffect } from "react";
import { useState } from "react";
import { socialLinkIcons } from "./options";
/**
 *
 * @param { string[] } taken Arrays of link keys, ex: ["facebook", "discord"..bla bla]
 * @param { (object: any) => void }  handleChange function to process selected events
 * @param { previousObject } previousObject current state
 * @returns
 */
function LinkDropDownComponent({ taken = [], handleChange, previousObject }) {
  const [searchTerm, setSearchTerm] = useState("");
  const perPage = 5;

  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(perPage);

  const next = () => {
    if (to !== filteredKeys.length) {
      setFrom(to + 1);
      let nextTo = to + perPage;
      if (nextTo > filteredKeys.length) {
        setTo(filteredKeys.length);
      } else {
        setTo(nextTo);
      }
    }
  };

  const previous = () => {
    if (from > 1) {
      let diff = to - from + 1;
      if (diff < perPage) {
        let toShouldBe = to - diff;
        setTo(toShouldBe);
        setFrom(toShouldBe - perPage + 1);
      } else {
        setTo(to - perPage);
        setFrom(from - perPage);
      }
    }
  };

  const [filteredKeys, setFileteredKeys] = useState([]);
  useEffect(
    () => {
      let filtered = Object.keys(socialLinkIcons).filter(
        (obj) =>
          obj.toLowerCase().includes(searchTerm.trim().toLowerCase()) &&
          !taken.includes(obj)
      );
      setFileteredKeys(filtered);
      if (filtered.length === 0) {
        setFrom(0);
        setTo(0);
      } else {
        setFrom(1);
        if (perPage > filtered.length) {
          setTo(filtered.length);
        } else {
          setTo(perPage);
        }
      }
    },
    //eslint-disable-next-line
    [searchTerm]
  );

  return (
    <div
      style={{
        width: "300px",
        height: "auto",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}>
      {/* navigations and paginations */}
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "auto",
          padding: "0.5em",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <button
          onClick={previous}
          variant="contained"
          style={{
            border: "none",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.5em",
            color: "white",
            backgroundColor: "#2979ff",
            cursor: "pointer",
          }}>
          <ArrowBack fontSize="small" />
        </button>
        {filteredKeys.length !== 0 && (
          <div>
            {Math.ceil(to / perPage)}/{Math.ceil(filteredKeys.length / perPage)}
          </div>
        )}
        <button
          onClick={next}
          variant="contained"
          style={{
            border: "none",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.5em",
            color: "white",
            backgroundColor: "#2979ff",
            cursor: "pointer",
          }}>
          <ArrowForward fontSize="small" />
        </button>
      </div>
      {/* search bar */}
      <input
        placeholder="Search icon"
        style={{
          width: "94%",
          margin: "0.5em",
          border: "1px solid lightgray",
          padding: "0.5em",
        }}
        onChange={(e) => setSearchTerm(e.target.value)}></input>
      {/* Icons holder */}

      {filteredKeys.length !== 0 ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            columnGap: "1em",
            rowGap: "1em",
            padding: "1em",
          }}>
          {filteredKeys.slice(from - 1, to).map((obj, i) => {
            let data = socialLinkIcons[obj];
            return (
              <div
                key={i}
                onClick={() =>
                  handleChange(previousObject, { ...data, medium: obj })
                }
                style={{
                  backgroundColor: data.themeColor ?? "black",
                  maxHeight: "2.5em",
                  minHeight: "2.5em",
                  maxWidth: "2.5em",
                  minWidth: "2.5em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "3px",
                  color: "white",
                  fontStyle: "normal !important",
                  cursor: "pointer",
                }}>
                <i
                  className={data.fontAwesomeRef}
                  style={{ fontStyle: "normal" }}></i>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "1em",
            color: "gray",
          }}>
          Nothing to show
        </div>
      )}

      {filteredKeys.length !== 0 && (
        <span style={{ padding: "1em" }}>
          {from} - {to} / {filteredKeys.length}
        </span>
      )}
    </div>
  );
}
export default LinkDropDownComponent;
