export const serializeQueryStringsFromObject = (obj) => {
  try {
    let keys = Object.keys(obj);
    let query = "";
    for (let i = 0; i < keys.length; i++) {
      if (!!obj[keys[i]] && !!(obj[keys[i]] + "").trim().length) {
        if (query.length === 0) {
          query += `?${keys[i] + ""}=${obj[keys[i]] + ""}`;
        } else {
          query += `&${keys[i] + ""}=${obj[keys[i]] + ""}`;
        }
      }
    }
    return query;
  } catch (e) {
    console.error(e);
    return "";
  }
};
