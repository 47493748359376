/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef, useMemo } from "react";
import url from "../../config/axios";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress, MenuItem, Pagination, Select } from "@mui/material";
import { IconButton } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TuneIcon from "@mui/icons-material/Tune";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import PopupMenu from "../../atoms/Popup/PopupMenu";
import DateRangePopup from "../../atoms/Popup/DateRangePopup";
import Failure from "../../assets/failure.png";
import moment from "moment";
import Popup from "reactjs-popup";
import DeleteOrderDialog from "../../components/TypedOrders/DeleteOrderDialogComponent";
import SimpleModal from "../../atoms/Dialogs/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationSettingsDetails } from "../../redux/slices/globalStateSlice";
import OrdersTable from "./components/OrdersTable";
import {
  SOCKET_EVENTS,
  selectSocketState,
} from "../../redux/slices/socketSlice";
import CustomSelectPrimary from "../../atoms/CustomSelectPrimary/CustomSelectPrimary";
import RadioCustom from "../../atoms/Radio/RadioCustom";

export default function Index() {
  const [orders, setOrders] = useState([]);
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [noRecord, setNoRecord] = useState(false);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [sortPopupMenuAnchor, setSortPopupMenuAnchor] = useState(null);
  const [filterPopupMenuAnchor, setFilterPopupMenuAnchor] = useState(null);
  const [sortByAlpha, setSortByAlpha] = useState(0);
  const [sortByPrice, setSortByPrice] = useState(0);
  const [sortByTime, setSortByTime] = useState(0);
  const [deliveryType, setDeliveryType] = useState("pickup");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);
  const [error, setError] = useState(null);
  const ref = useRef();
  const admin = JSON.parse(localStorage.getItem("Admin")) || null;
  const dispatch = useDispatch();
  const socket = useSelector(selectSocketState);

  useEffect(() => {
    dispatch(getNotificationSettingsDetails(admin[0].outletChainID));
    socket.on(SOCKET_EVENTS.ORDER_PLACED, () => {
      getAllOrders({ ...queryData });
    });
  }, []);

  // const openTooltip = () => ref.current.open();
  // const closeTooltip = () => {
  //   ref.current.close();
  //   setSearchTerm("");
  // };
  const handleOpenSortPopupMenu = (event) => {
    setSortPopupMenuAnchor(event.currentTarget);
  };
  const handleOpenFilterPopupMenu = (event) => {
    setFilterPopupMenuAnchor(event.currentTarget);
  };
  const initialPaginationData = {
    page: 1,
    limit: 30,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);
      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 30
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  const performSearchOnEnter = () => {
    setPrevSearchTerm(searchTerm);
    handleChange("search", searchTerm, true);
  };
  const handleSearchTermChange = (value = "") => {
    setSearchTerm((value + "").trim());
    if (!noRecord) {
      if (
        (value + "").trim().length === 0 &&
        prevSearchTerm.trim().length !== 0
      ) {
        handleChange("search", "", true);
      }
    }
  };
  //search
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 30
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  useEffect(() => {
    if (parsed) {
      getAllOrders({ ...queryData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsed, queryData]);
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/typed-order-outlet${queryString}`);
    setQueryData({ ...query });
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  useEffect(() => {
    let queryParams = {
      ...queryData,
      sortByAlpha,
      sortByPrice,
      sortByTime,
      fromDate,
      toDate,
      isDelivery: deliveryType === "pickup" ? false : true,
    };
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    // queryParams.isDelivery = deliveryType === "pickup" ? false : true
    setTableLoading(true)
    url
      .get(`/v1/user-orders/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          //excklude orders without name of the customer
          if (res.data.data.orders.length === 0 && searchTerm) {
            setError("Order(s) not found");
          }
          if (
            !prevSearchTerm.trim().length &&
            !res.data?.data?.orders?.length
          ) {
            setNoRecord(true);
          }
          const filtered = res.data.data.orders;
          // .filter((order) => !!order?.customer?.name)
          // .sort((obj1, obj2) =>
          //   new Date(obj1?.createdAt) > new Date(obj2?.createdAt) ? -1 : 1
          // );
          setOrders(filtered);
          setUnfiltered(filtered);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data.paginationData,
          });
          setLoading(false);
          setTableLoading(false);
          // setTotalPage();
          // setminPro(page * 50 - 50 + 1);
        }
      });
  }, [sortByAlpha, sortByPrice, sortByTime, fromDate, toDate, deliveryType]);
  // Making api call here
  const getAllOrders = () => {
    setTableLoading(true);
    setLoading(true);
    let queryParams = {
      ...queryData,
      sortByAlpha,
      sortByPrice,
      sortByTime,
      fromDate,
      toDate,
    };
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });

    url
      .get(`/v1/user-orders/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          //excklude orders without name of the customer
          if (res.data.data.orders.length === 0 && searchTerm) {
            setError("Order(s) not found");
          }
          if (
            !prevSearchTerm.trim().length &&
            !res.data?.data?.orders?.length
          ) {
            setNoRecord(true);
          }
          const filtered = res.data.data.orders;
          // .filter((order) => !!order?.customer?.name)
          // .sort((obj1, obj2) =>
          //   new Date(obj1?.createdAt) > new Date(obj2?.createdAt) ? -1 : 1
          // );
          setOrders(filtered);
          setUnfiltered(filtered);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data.paginationData,
          });
          setLoading(false);
          setTableLoading(false);
          // setTotalPage();
          // setminPro(page * 50 - 50 + 1);
        }
      });
  };

  function checkColor(value) {
    let result;
    switch (value) {
      case "Processing":
        result = "#FFC700";
        break;
      case "New":
        result = "blue";
        break;
      case "Dispatched":
        result = "grey";
        break;
      case "Cancelled":
        result = "brown";
        break;
      default:
        result = "#0CD600";
        break;
    }
    return result;
  }
  function backgroundColor(value) {
    let result;
    switch (value) {
      case "Processing":
        result = "rgb(251, 255, 194)";
        break;
      case "Dispatched":
        result = "rgb(251, 255, 150)";
        break;
      case "New":
        result = "rgba(108, 149, 255, 0.1)";
        break;
      case "Cancelled":
        result = "rgba(255, 109, 109, 0.08)";
        break;
      case "Delivered":
        result = "color-purple";
        break;
      default:
        result = "rgba(12, 214, 0, 0.1)";
        break;
    }
    return result;
  }

  const Tablenew = useMemo(
    () => (
      <OrdersTable
        orders={orders}
        setOrderToDelete={setOrderToDelete}
        backgroundColor={backgroundColor}
        checkColor={checkColor}
        deliveryType={deliveryType}
        loading={tableLoading}
        
      />
    ),
    [orders, deliveryType, tableLoading]
  );
  const handleOrderTypeChange = (value) => {
    setDeliveryType(value);
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <CircularProgress />
            </div>
          ) : (
            <div className="row" style={{ borderRadius: "13px" }}>
              <div className="col-md-12">
                <div className="row">
                  <div
                    style={{
                      height: "25px",
                      marginLeft: "-32px",
                      backgroundColor: "#FFA382",
                      marginTop: "5px",
                    }}>
                    <span style={{ color: "#FFA382" }}>.</span>
                  </div>
                  <div className="col-md-3 d-flex">
                    <div
                      style={{
                        color: "#FFA382",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}>
                      Orders
                    </div>
                  </div>
                  <div className="col-md-4 filters-bar">
                    <div className="dropdown"></div>
                    <div className="dropdown">
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="/">
                          Action
                        </a>
                        <a className="dropdown-item" href="/">
                          Another action
                        </a>
                        <a className="dropdown-item" href="/">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 d-flex align-items-center flex-gap-1">
                    <RadioCustom
                      defaultValue={deliveryType}
                      onChange={handleOrderTypeChange}
                      options={[
                        { label: "Pickup", value: "pickup" },
                        { label: "Delivery", value: "delivery" },
                      ]}
                    />
                    <div className="input-group">
                      <input
                        type="text"
                        onChange={(e) => {
                          handleSearchTermChange(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            performSearchOnEnter();
                          }
                        }}
                        value={searchTerm}
                        style={{ borderRight: "none" }}
                        className="form-control"
                        placeholder="Search"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                      />
                      {searchTerm?.length ? (
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            onClick={() => handleSearchTermChange("")}
                            style={{
                              backgroundColor: "white",
                              borderLeft: "none",
                              paddingLeft: 0,
                              cursor: "pointer",
                            }}>
                            <i className="fa fa-times"></i>
                          </div>
                        </div>
                      ) : null}
                      <div className="input-group-prepend">
                        <div
                          className="input-group-text newSearchIcon"
                          id="btnGroupAddon">
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        left: "50px",
                      }}>
                      <IconButton onClick={handleOpenFilterPopupMenu}>
                        <FilterAltIcon />
                      </IconButton>
                      <DateRangePopup
                        label="Purchased On"
                        anchorEl={filterPopupMenuAnchor}
                        setAnchorEl={setFilterPopupMenuAnchor}
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        sortByTime={sortByTime}
                        setSortByTime={setSortByTime}
                        customFormat="YYYY-MM-DD"
                      />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        left: "35px",
                      }}>
                      <IconButton onClick={handleOpenSortPopupMenu}>
                        <TuneIcon />
                      </IconButton>
                      <PopupMenu
                        anchorEl={sortPopupMenuAnchor}
                        setAnchorEl={setSortPopupMenuAnchor}
                        menuItems={[
                          {
                            label: "Sort: Older to Newer",
                            onClick: () => setSortByAlpha(1),
                          },
                          {
                            label: "Sort: Newer to Older",
                            onClick: () => setSortByAlpha(-1),
                          },
                          {
                            label: "Price: Low to High",
                            onClick: () => setSortByPrice(1),
                          },
                          {
                            label: "Price: High to Low",
                            onClick: () => setSortByPrice(-1),
                          },
                        ]}
                      />
                    </div>
                  </div>
                  {!!orders.length && (
                    <>
                      {/* <div className="col-md-1 pagination" style={{ justifyContent: "space-evenly" }}>

                      <img src={sort} />

                    </div> */}
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-12">{Tablenew}</div>
            </div>
          )}
        </div>
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}>
            <Pagination
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>
      </div>

      {error ? (
        <SimpleModal
          severity={"error"}
          highlights="Order(s) not found"
          onClose={() => {
            setError(null);
            setSearchTerm("");
          }}
        />
      ) : null}

      {/* delete order popup */}
      {orderToDelete && (
        <DeleteOrderDialog
          orderInfo={orderToDelete}
          onDone={() => {
            setOrderToDelete(null);
            setQueryData({ ...initialPaginationData });
          }}
          onCancel={() => setOrderToDelete(null)}
        />
      )}
    </div>
  );
}
