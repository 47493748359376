import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useHistory } from "react-router-dom";

const InvokeLayoutComponent = () => {
  const history = useHistory();
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const logout = () => {
    const tempRememberMeState = localStorage.getItem("remeberMeState");
    localStorage.clear();
    try {
      localStorage.setItem(
        "remeberMeState",
        tempRememberMeState ??
          JSON.stringify({ rememberMe: false, email: "", password: "" })
      );
    } catch (e) {}
    history.push("/");
  };
  return (
    <Dialog
      TransitionComponent={Transition}
      // keepMounted
      open={true}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{"Session Expired!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <b>Please logout and login again</b>
          <br />
          <span style={{ fontSize: "80%" }}>
            This site requires cookies to be enabled in your browser
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={logout}>Logout</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvokeLayoutComponent;
