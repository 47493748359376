import React from 'react'

export default function Index() {
    return (
        <div>
            Catalog Main Page
            <h6>
                abc
            </h6>
        </div>
    )
}
