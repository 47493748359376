import { useTheme } from "@mui/material";
import React, { useState } from "react";

/**
 * {
 *  options: Array<{
 *      label: string, value: string
 *  }>,
 *  defaultValue: string,
 *  onChange: (value: string) => void
 * }
 */
function RadioCustom({ options, defaultValue, onChange }) {
  const handleClick = (value) => {
    setValue(value);
    if (onChange) onChange(value);
  };
  const [value, setValue] = useState(defaultValue);
  const theme = useTheme();

  return (
    <div
      style={{
        width: "100%",
        padding: "0.25rem",
        display: "flex",
        border: "0.1px solid #eaeaea",
        borderRadius: "9999px",
      }}
    >
      {options.map((option) => (
        <span
          style={{
            flex: 1,
            padding: "0.25rem",
            textAlign: "center",
            transition: "all .3s ease-out",
            cursor: "pointer",
            borderRadius: "9999px",
            fontWeight: "bold",
            ...(option.value === value
              ? {
                  backgroundColor: theme.palette.secondary.main,
                  color: "white",
                }
              : {
                  backgroundColor: "transparent",
                  color: "#212121",
                }),
          }}
          onClick={() => handleClick(option.value)}
        >
          {option.label}
        </span>
      ))}
    </div>
  );
}

export default RadioCustom;
