import { Dialog } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import url from "../../../config/axios";
import EditNotificationSettingsPopUp from "../../../components/NotificationSettingsPage/EditNotificationSettings";
import CircularProgress from "@mui/material/CircularProgress";
import { colors } from "../../../Style.style";
const initialCurrentData = {
  others: {
    displayName: "Others",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "",
      adminSite: "",
    },
    disabled: true,
  },
  register: {
    displayName: "Register",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Welcome to Bloom",
      adminSite: "Welcome to the system",
    },
  },
  orderConfirmation: {
    displayName: "Order Confirmation",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order has been confirmed",
      adminSite: "Order Confirmed",
    },
  },
  orderPending: {
    displayName: "Order Pending",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order is pending",
      adminSite: "Order Pending",
    },
  },
  orderProcessing: {
    displayName: "Order Processing",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order is being processed",
      adminSite: "Order Processing",
    },
  },
  orderDispatched: {
    displayName: "Order Dispatched",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order dispatched",
      adminSite: "Order Dispatched",
    },
  },
  orderReceived: {
    displayName: "Order Received",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order has been received",
      adminSite: "Order Received",
    },
  },
  newOrder: {
    displayName: "New Order",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order has been placed",
      adminSite: "Order placed",
    },
  },
  dealAdded: {
    displayName: "New Deal",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "A new deal placed",
      adminSite: "You placed a new deal",
    },
  },
  dealUpdated: {
    displayName: "Deal Updated",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Deal updated",
      adminSite: "Deal has been updated",
    },
  },
  couponAdded: {
    displayName: "New Coupon",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Coupon added",
      adminSite: "You added a coupon",
    },
  },
  couponUpdated: {
    displayName: "Coupon Updated",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Coupon updated",
      adminSite: "You updated a coupon",
    },
  },
  loyaltyPointsEarned: {
    displayName: "Added Loyalty points",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Loyalty points updated",
      adminSite: "You updated loyalty points",
    },
  },
};

function NotificationSettingsPage({ invokeLogout }) {
  // eslint-disable-next-line
  const [currentData, setCurrentData] = useState({ ...initialCurrentData });
  const [toEdit, setToEdit] = useState(null);
  const [checkUpdate, setUpdate] = useState(false);

  const openEditPopup = (data) => {
    setToEdit(data);
  };
  /**
   * 
   * @param {any } modified modified object Ex: {
    displayName: "Register",
    type: "register",
    mediums: {
      email: true,
      system: false,
      mobile: true,
      sms: false,
    },
    messages: {
      particularUser: "Welcome to Bloom",
      adminSite: "Welcome to the system",
    },
  }
   */
  const closeEditPopup = (modified) => {
    setToEdit(null);
    let toSet = { ...currentData };
    let { type, ...others } = modified ?? {};
    if (!!type) {
      toSet[type] = {
        ...others,
      };
    }
    if (checkUpdate === true) {
      getAllSocialLinks();
    } else {
      getAllSocialLinks();
    }
  };
  const [inProgress, setInProgress] = useState(true);
  const getAllSocialLinks = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    const outletChainID = adminID[0]?.outletChainID ?? null;
    url
      .get(`/v1/consumer-notification-settings`)
      .then((response) => {
        const preferences = response?.data?.data?.settings?.topics ?? {};
        setCurrentData({ ...preferences });
        setInProgress(false);
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  //fetch data
  useEffect(() => {
    getAllSocialLinks();
  }, []);

  return (
    <div className="col-md-12">
      {inProgress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          <table className="table table-striped-cust">
            <thead>
              <tr>
                <th scope="col">Event</th>
                <th scope="col"></th>

                <th scope="col">Type</th>
                <th scope="col"></th>

                <th scope="col">Message</th>
                <th scope="col"></th>

                <th scope="col">Status</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Action</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(currentData).map((obj, i) => {
                let data = currentData[obj];
                if (data?.hidden === true) {
                  delete currentData[obj];
                  return null;
                }

                let notificationTypes = data?.mediums ?? {};
                // filter mediums with enabled true return the display name
                let mediumNames = [];
                Object.keys(notificationTypes).filter(
                  (medium) =>
                    notificationTypes[medium].enabled === true &&
                    mediumNames.push(notificationTypes[medium].displayName)
                );

                if (data?.disabled) {
                  return null;
                }
                return (
                  <tr key={i}>
                    <td style={{ color: "#1E293B" }} colSpan={2}>
                      When {data.displayName?.split("On")[1]}
                      {/* {data.displayName} */}
                    </td>
                    <td style={{ color: "#1E293B" }} colSpan={2}>
                      {!!mediumNames.length ? mediumNames.join(", ") : "--"}
                    </td>
                    <td style={{ color: "#1E293B" }} colSpan={2}>
                      {data.messages?.userMessage?.highlights}
                    </td>
                    <td colSpan={2}>
                      {data?.enabled === true ? (
                        <div
                          className="newClass"
                          id="coupons"
                          style={{
                            backgroundColor: `rgba(12, 214, 0, 0.1)`,
                          }}>
                          <p
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                              fontSize: "13px",
                              paddingTop: "2px",
                            }}>
                            <span style={{ color: "#0CD600" }}>Enabled</span>
                          </p>
                        </div>
                      ) : (
                        <div
                          className="newClass"
                          id="coupons"
                          style={{
                            backgroundColor: `rgba(255, 109, 109, 0.08)`,
                          }}>
                          <p
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                              fontSize: "13px",
                              paddingTop: "2px",
                            }}>
                            <span style={{ color: "red" }}>Disabled</span>
                          </p>
                        </div>
                      )}
                    </td>
                    <td colSpan={2}></td>
                    <td colSpan={2}>
                      <div
                        onClick={() => {
                          openEditPopup({ ...data, type: obj });
                          setUpdate(false);
                        }}
                        style={{
                          cursor: "pointer",
                        }}>
                        <i
                          className="fa fa-pen"
                          style={{
                            color: colors.accent,
                            paddingRight: "3px",
                          }}></i>
                        <span style={{ color: colors.accent }}>Edit</span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!!toEdit && (
            <Dialog open={true} onClose={closeEditPopup} fullWidth={true}>
              <EditNotificationSettingsPopUp
                onClose={closeEditPopup}
                toEdit={toEdit}
                invokeLogout={invokeLogout}
                setUpdate={setUpdate}
              />
            </Dialog>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default NotificationSettingsPage;
