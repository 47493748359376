import { SocialLinks } from "social-links";
export const socialLinkIcons = {
  facebook: {
    fontAwesomeRef: "fa-brands fa-facebook-f",
    themeColor: "#4267B2",
    dropdownColor: "white",
    starterLink: "https://www.facebook.com",
    validate: (link) => {
      const sociaLinks = new SocialLinks();
      return sociaLinks.isValid("facebook", link);
    },
  },
  twitter: {
    fontAwesomeRef: "fa-brands fa-twitter",
    themeColor: "#1DA1F2",
    dropdownColor: "white",
    starterLink: "https://www.twitter.com",
    validate: (link) => {
      const sociaLinks = new SocialLinks();
      return sociaLinks.isValid("twitter", link);
    },
  },
  instagram: {
    fontAwesomeRef: "fa-brands fa-instagram",
    themeColor: "#3f729b",
    dropdownColor: "white",
    starterLink: "https://www.instagram.com",
    validate: (link) => {
      const sociaLinks = new SocialLinks();
      return sociaLinks.isValid("instagram", link);
    },
  },
  twitch: {
    fontAwesomeRef: "fa-brands fa-twitch",
    themeColor: "#6441a5",
    dropdownColor: "white",
    starterLink: "https://www.twitch.com",
    validate: (link) => {
      const sociaLinks = new SocialLinks();
      return sociaLinks.isValid("twitch", link);
    },
  },
  // discord: {
  //   fontAwesomeRef: "fa-brands fa-discord",
  //   themeColor: "#191970",
  //   dropdownColor: "white",
  //   starterLink: "https://www.discord.com",
  // },
  linkedIn: {
    fontAwesomeRef: "fa-brands fa-linkedin-in",
    themeColor: "#0e76a8",
    dropdownColor: "white",
    starterLink: "https://www.linkedin.com",
    validate: (link) => {
      const sociaLinks = new SocialLinks();
      return sociaLinks.isValid("linkedin", link);
    },
  },
  web: {
    fontAwesomeRef: "fa-solid fa-globe",
    themeColor: "#0000FF",
    dropdownColor: "white",
    starterLink: "https://www.google.com",
    validate: (link) => {
      try {
        new URL(link);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
  youtube: {
    fontAwesomeRef: "fa-brands fa-youtube",
    themeColor: "#FF0000",
    dropdownColor: "white",
    starterLink: "https://www.youtube.com",
    validate: (link) => {
      // const sociaLinks = new SocialLinks();
      // return sociaLinks.isValid("youtube", link);

      return (link + "").match(
        // eslint-disable-next-line
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
      );
    },
  },
  // vine: {
  //   fontAwesomeRef: "fa-brands fa-vine",
  //   themeColor: "#00a478",
  //   dropdownColor: "white",
  //   starterLink: "https://www.vine.com",
  // },
  tiktok: {
    fontAwesomeRef: "fa-brands fa-tiktok",
    themeColor: "#ff0050",
    dropdownColor: "white",
    starterLink: "https://www.tiktok.com",
    validate: (link) => {
      const sociaLinks = new SocialLinks();
      return sociaLinks.isValid("tiktok", link);
    },
  },
};
