import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "./pages/Routes/Routes.js";
import Login from "./pages/Authentication/index";
import Sidenav from "./components/SideNav";
import SidenavNew from "./components/SidenavNew/Sidenav";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PersonIcon from "@mui/icons-material/Person";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import WebIcon from "@mui/icons-material/Web";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ForumIcon from "@mui/icons-material/Forum";
import GavelIcon from "@mui/icons-material/Gavel";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import GrowflowLogo from "./assets/pbBleaum.png";
import MainLogoWithText from "./assets/Logo/with-text.png";
import MainLogoWithoutText from "./assets/Logo/without-text.png";
import {
  abortProgressAction,
  removeErrorMessageFromAuthStateAction,
  selectAuthStateErrorMessage,
  selectifMultiChainAdmin,
  selectIfSwitchingProgress,
} from "./redux/slices/authSlice";
import { CustomBackdrop } from "./components/common/backdrop";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { OutletChooser } from "./components/common/outletChooser";
import { selectIfChooserOpen } from "./redux/slices/ocSwitchSlice";
import {
  initializeSocketStateThunk,
  resetSocketStateAction,
  selectSocketState,
  SOCKET_EVENTS,
  // setSocketAsReconnectAction,
  setSocketAsConnetedAction,
  setSocketAsDisconnectedAction,
} from "./redux/slices/socketSlice";
import {
  clearRootNotificationMessageAction,
  selectRootNotification,
} from "./redux/slices/rootNotificationSlice";
import OutletChooserNew from "./components/common/OutletChooserNew.jsx";
import { OutletSwitchBackdrop } from "./components/common/OutletSwitchBackdrop.jsx";

function App() {
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const isSwitching = useSelector(selectIfSwitchingProgress);
  const authError = useSelector(selectAuthStateErrorMessage);
  const chooserOpen = useSelector(selectIfChooserOpen);
  const dispatch = useDispatch();
  const clearError = () => {
    dispatch(removeErrorMessageFromAuthStateAction());
  };
  const socket = useSelector(selectSocketState);

  // Adding google api script
  // React.useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&loading=async&libraries=places&callback=initMap`;
  //   script.async = true;
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  React.useEffect(
    () => {
      if (!socket) {
        dispatch(initializeSocketStateThunk());
      } else {
        socket.on(SOCKET_EVENTS.CONNECT, () =>
          dispatch(setSocketAsConnetedAction())
        );
        socket.on(SOCKET_EVENTS.DISCONNECT, () =>
          dispatch(setSocketAsDisconnectedAction())
        );
        // socket.on(SOCKET_EVENTS.RECONNECT, () =>
        //   dispatch(setSocketAsReconnectAction())
        // );
      }
      return () => {
        if (socket) {
          dispatch(resetSocketStateAction());
        }
      };
    },
    //eslint-disable-next-line
    [socket]
  );
  React.useEffect(
    () => {
      try {
        if (isSwitching) {
          dispatch(abortProgressAction());
        }
        // const adminData = JSON.parse(localStorage.getItem("Admin"));
        // if (Array.isArray(adminData) && !!adminData?.length) {
        //   const faviconLogoFileSource =
        //     adminData[0]?.media?.faviconLogoFileSource ?? null;
        //   if (faviconLogoFileSource) {
        //     let link = document.querySelector("link[rel~='icon']");
        //     if (!link) {
        //       link = document.createElement("link");
        //       link.rel = "icon";
        //       document.getElementsByTagName("head")[0].appendChild(link);
        //     }
        //     link.href = faviconLogoFileSource;
        //   }
        // }
      } catch (e) {}
    },
    //eslint-disable-next-line
    []
  );
  function setColors(newColor) {
    document.documentElement.style.setProperty(
      "--color-primary-main",
      newColor
    );
  }
  const rootNotification = useSelector(selectRootNotification);
  return (
    <Router>
      {rootNotification?.length ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => {
            dispatch(clearRootNotificationMessageAction());
          }}
        >
          <Alert
            onClose={() => {
              dispatch(clearRootNotificationMessageAction());
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            {rootNotification}
          </Alert>
        </Snackbar>
      ) : null}
      <Switch>
        {/* Uncommend this line for new switcher */}
        {/* {isMultiChainAdmin && (isSwitching || chooserOpen) && <OutletSwitchBackdrop />} */}

        {/* Uncommend these two lines for old switcher */}
        {isMultiChainAdmin && isSwitching && <CustomBackdrop />}
        {isMultiChainAdmin && chooserOpen && !isSwitching && <OutletChooser />}

        {isMultiChainAdmin && authError && (
          <Snackbar open={true} autoHideDuration={6000} onClose={clearError}>
            <Alert onClose={clearError} severity="error" sx={{ width: "100%" }}>
              {authError ?? "Something went wrong"}
            </Alert>
          </Snackbar>
        )}
        <Route exact path="/" component={LoginContainer} />
        <Route component={DefaultContainer} />
      </Switch>
    </Router>
  );
}

const LoginContainer = () => (
  <div className="container">
    <Route exact path="/" component={Login} />
  </div>
);

const DefaultContainer = () => {
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const NavItems = createNavItems(isMultiChainAdmin);
  return (
    <div>
      <div className="App">
        <div className="backdrop"></div>
        <div className="row row-main">
          {/* <Sidenav />
        <div className="col-lg-10 col-md-8 col-sm-8 right-column">
          <Header />
          <Route component={Routes} />
        </div> */}
          <SidenavNew items={NavItems} logoSource={MainLogoWithoutText}>
            <Route component={Routes} />
          </SidenavNew>
        </div>
      </div>
    </div>
  );
};
const createNavItems = (isMultiChainAdmin) => {
  return {
    firstItems: [
      {
        name: "dashboard",
        label: "Dashboard",
        icon: <DashboardIcon />,
        url: "/dashboard",
      },
      {
        name: "catalog",
        label: "Catalog",
        icon: <LocalOfferIcon />,
        subItems: [
          {
            name: "products",
            label: "Inventory",
            url: "/inventory-outlet",
            headingLabel: "Catalog",
          },
          {
            name: "categories",
            label: "Categories",
            url: "/categories",
            headingLabel: "Catalog",
          },
          {
            name: "manufacturers",
            label: "Manufacturers",
            url: "/brands",
            headingLabel: "Catalog",
          },
          // { name: "album", label: "Album", url: "/album", headingLabel: "Album" }
        ],
      },
      // {
      //   name: "users", //old
      //   label: "Users",
      //   icon: <PersonIcon />,
      //   url: "/users",
      // },
      {
        name: "users",
        label: "Users",
        icon: <PersonIcon />,
        url: "/typed-users",
      },
      {
        name: "orders", //new
        label: "Orders",
        icon: <ShoppingCartIcon />,
        url: "/typed-order-outlet",
      },
      // {
      //   name: "orders", //old
      //   label: "Orders",
      //   icon: <LocalShippingIcon />,
      //   url: "/order-outlet",
      // },
      // {
      //   name: "promotions",//old
      //   label: "Promotions",
      //   icon: <AddBusinessIcon />,
      //   subItems: [
      //     {
      //       name: "banners",
      //       label: "Banners",
      //       url: "/banner",
      //       headingLabel: "Promotions",
      //     },
      //     {
      //       name: "deals",
      //       label: "Deals",
      //       url: "/deals",
      //       headingLabel: "Promotions",
      //     },
      //     {
      //       name: "Promotions",
      //       label: "Coupons",
      //       url: "/discounts-outlet",
      //       headingLabel: "Promotions",
      //     },
      //     {
      //       name: "special-day",
      //       label: "Special Days",
      //       url: "/Special-Day",
      //       headingLabel: "Promotions",
      //     },
      //   ],
      // },
      {
        name: "promotions new",
        label: "Promotions",
        icon: <AddBusinessIcon />,
        subItems: [
          {
            name: "typed-coupons",
            label: "Coupons",
            url: "/typed-coupons",
            headingLabel: "Coupons",
          },
          {
            name: "typed-deals",
            label: "Deals",
            url: "/typed-deals",
            headingLabel: "Deals",
          },
          {
            name: "typed-banners",
            label: "Banners",
            url: "/typed-banners",
            headingLabel: "Banners",
          },
        ],
      },
      {
        name: "pages",
        label: "Pages",
        icon: <WebIcon />,
        url: "/Page",
      },
      ...(isMultiChainAdmin
        ? [
            {
              name: "custom-pages",
              label: "Custom Pages",
              icon: <DesignServicesIcon />,
              url: "/custom-pages",
            },
          ]
        : []),
      // {
      //   name: "Loyalty", //old
      //   label: "Loyalty",
      //   icon: <LoyaltyIcon />,
      //   url: "/loyalty-points",
      // },
      {
        name: "Loyalty",
        label: "Loyalty",
        icon: <LoyaltyIcon />,
        subItems: [
          {
            name: "Loyalty Points",
            label: "Loyalty Points",
            url: "/loyalty-points",
            headingLabel: "Loyalty",
          },
          // {
          //   name: "Loyalty Rules",
          //   label: "Loyalty Rules",
          //   url: "/loyalty-rules",
          //   headingLabel: "Loyalty",
          // },
          {
            name: "Reward Plan",
            label: "Reward Plan",
            url: "/redemption-policy",
            headingLabel: "Loyalty",
          },
        ],
      },
      {
        name: "notifications",
        label: "Notifications",
        icon: <NotificationsIcon />,
        subItems: [
          {
            name: "notificationss",
            label: "My Notifications",
            icon: <NotificationsIcon />,
            url: "/typed-notifications",
            headingLabel: "Notifications",
          },
          {
            name: "notifications-settings",
            label: "Notification Settings",
            url: "/notification-settings",
            headingLabel: "Notifications",
          },
        ],
      },
      // {
      //   name: "notifications", //old
      //   label: "Notifications",
      //   icon: <NotificationsIcon />,
      //   url: "/my-notifications",
      // },
      {
        name: "chat",
        label: "Chat",
        icon: <ForumIcon />,
        url: "/chat",
      },
      {
        name: "settings",
        label: "Settings",
        icon: <SettingsIcon />,
        url: "/general-settings",
      },
      {
        name: "delivery-settings",
        label: "Delivery Settings",
        icon: <LocalShippingIcon />,
        url: "/delivery-settings",
      },
      {
        name: "tax-profile",
        label: "Tax Profile",
        icon: <GavelIcon />,
        url: "/tax-profile",
      },
    ],
    secondItems: [
      {
        name: "logout",
        label: "Logout",
        color: "red",
        icon: <LogoutIcon color="error" />,
        url: "/logout",
      },
    ],
  };
};
// const OutletAdminContainer = () => (
//   <div>
//     <div className="App">
//       <div className="backdrop"></div>
//       <div className="row row-main">
//         {/* <Sidenav /> */}
//         <div className="col-lg-10 col-md-8 col-sm-8">
//           <Header />
//           <Route component={OutletAdminRoutes} />
//         </div>
//       </div>
//     </div>
//   </div>
// )

export default App;
