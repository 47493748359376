import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styled from "@emotion/styled";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function MultipleSelect({ label, value, onChange, options }) {
  const theme = useTheme();
  const [localValue, setLocalValue] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const finalValue = typeof value === "string" ? value.split(",") : value;

    setLocalValue(finalValue);
    if (onChange) onChange(finalValue);
  };

  return (
    <SelectWrapper>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={value ?? localValue}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          style={{
            // backgroundColor: "red",
            padding: 8,
            paddingLeft: 15,
            marginTop: 15,
            border: '1px solid #e2e8f0'
          }}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={getStyles(option.value, localValue, theme)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
 .MuiSelect-multiple.MuiOutlinedInput-input {
    display: flex;
    align-items: center;
 }
 .MuiInputLabel-root {
    transform: translate(14px, 28px) scale(1);
 }
 .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(0px, -10px) scale(0.85);
 }
`
