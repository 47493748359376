import React, { useEffect, useState } from "react";
import ContentContainer from "../../atoms/ContentContainer";
import TablePrimary from "../../atoms/Table/TablePrimary";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Divider, IconButton, Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmationDialog from "../../atoms/Dialogs/ConfirmationDialog";
import FeedbackDialog from "../../atoms/Dialogs/FeedbackDialog";
import axios from "../../config/axios";
import NoData from "../../atoms/Placeholders/NoData";
import { Button } from "@mui/material";

function ListTaxProfiles() {
  const history = useHistory();
  const [initLoading, setInitLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [taxProfiles, setTaxProfiles] = useState([]);
  const [stagedItemForDelete, setStagedItemForDelete] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(true);
  const [deleteFeedbackDialogOpen, setDeleteFeedbackDialogOpen] =
    useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [defaultTaxProfiles, setDefaultTaxProfiles] = useState([]);

  useEffect(() => {
    fetchTaxProfiles();
  }, []);

  const handleStageDelete = (pageItem) => {
    setDeleteDialogOpen(true);
    setStagedItemForDelete(pageItem);
  };
  const handleDelete = async () => {
    setDeleteDialogOpen(false);
    setDeleteLoading(true);
    try {
      const res = await axios.delete(
        `/v1/tax-profile/delete?id=${stagedItemForDelete["_id"]}`
      );
      console.log("Res status", res.status, res.data);
      await fetchTaxProfiles();
      setDeleteSuccess(true);
    } catch (error) {
      console.log("Res", error.response);
      if (error.response?.data?.errorCode === "IN_USE") {
        setErrorMessage(error.response.data.error);
      }
      setDeleteDialogOpen(false);
      setDeleteSuccess(false);
    }
    setDeleteLoading(false);
    setDeleteFeedbackDialogOpen(true);
  };
  const fetchTaxProfiles = async () => {
    setInitLoading(true);
    try {
      const res = await axios.get("/v1/tax-profile/list");
      setTaxProfiles(res.data.data.customTaxProfiles);
      setDefaultTaxProfiles(res.data.data.defaultTaxProfiles);
    } catch (error) {
      console.log("Failed to load data", error);
    }
    setInitLoading(false);
  };
  const columns = [
    { id: "name", label: "Name" },
    {
      id: "_id",
      label: "Action",
      render: (rowData) => (
        <div className="d-flex gap-2 justify-content-end">
          <IconButton
            // onClick={() => history.push(`/custom-page/edit/${rowData.id}`)}
            onClick={() => history.push(`/tax-profile/edit/${rowData["_id"]}`)}
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleStageDelete(rowData)} color="error">
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const defaultTaxProfileColumns = [
    { id: "name", label: "Name" },
    {
      id: "_id",
      label: "Action",
      render: (rowData) => (
        <div className="d-flex gap-2 justify-content-end">
          <IconButton
            // onClick={() => history.push(`/custom-page/edit/${rowData.id}`)}
            onClick={() => history.push(`/tax-profile/edit/${rowData["_id"]}`)}
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  return !initLoading ? (
    <>
      {defaultTaxProfiles.length ? (
        <>
          <ContentContainer heading={"Default Tax Profiles"}>
            <TablePrimary
              columns={defaultTaxProfileColumns}
              rows={defaultTaxProfiles}
              isLoading={initLoading}
              emptyMessage={{
                heading: "No Tax Profiles Found!",
                description:
                  "Looks like you haven't created any tax profiles yet. Start creating custom tax profiles now to manage taxes for your delivery & sales!",
              }}
            />
          </ContentContainer>
          <div style={{ margin: '10px 0px'}}>
            <Divider />
          </div>
          <ContentContainer
            heading={"Tax Profiles"}
            _headingRightActionComponent={
              <button
                className="btn btn-primary border-0"
                style={{ backgroundColor: "#FFA382" }}
                onClick={() => {
                  history.push("/tax-profile/create");
                }}
              >
                <i className="fas fa-plus-circle"></i>
                Create
              </button>
            }
          >
            <TablePrimary
              columns={columns}
              rows={taxProfiles}
              isLoading={initLoading}
              emptyMessage={{
                heading: "No Tax Profiles Found!",
                description:
                  "Looks like you haven't created any tax profiles yet. Start creating custom tax profiles now to manage taxes for your delivery & sales!",
              }}
            />
            <ConfirmationDialog
              open={deleteDialogOpen}
              setOpen={setDeleteDialogOpen}
              title={`Deleting Page`}
              description={`Are you sure you want to delete page ${stagedItemForDelete?.name}?`}
              onConfirm={handleDelete}
            />
            <FeedbackDialog
              open={deleteFeedbackDialogOpen}
              setOpen={setDeleteFeedbackDialogOpen}
              isSuccess={deleteSuccess}
              successData={{
                title: "Successfully Delete",
                description: `Page ${stagedItemForDelete?.name} is deleted successfully`,
              }}
              failedData={{
                title: "Failed to Delete",
                description:
                  errorMessage ??
                  `Page ${stagedItemForDelete?.name} has failed to delete, please check your internet connection and reload the page then try again. If it still doesn't work try re logging in.`,
              }}
            />
          </ContentContainer>
        </>
      ) : (
        <ContentContainer heading={"Tax Profiles"}>
          <NoData
            heading="Start Setting Up Tax Profile"
            description="To get started, let's set up your tax profiles, which will define the tax structure for your scales. Tax profiles are also required for the delivery module. We'll begin by creating base tax profiles for both cannabis and non-cannabis products, ensuring your business is ready to handle tax requirements smoothly."
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={() => history.push("/tax-profile/setup")}
            >
              Get Started
            </Button>
          </div>
        </ContentContainer>
      )}
    </>
  ) : (
    <div className="content-container" style={{ padding: '20px 10px'}}>
      <Skeleton animation="wave" width="80%" />
      <Skeleton animation="wave" width="80%" />
      <Skeleton animation="wave" width="60%" />
    </div>
  );
}

export default ListTaxProfiles;
